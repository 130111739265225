import { useState } from 'react';
import { useContext } from 'react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { FilterContext } from '../../../context/filterStatementContext';
import { DisabledFilterDisplay } from '../filters/filterBar/DisabledFilterDisplay';
import { FilterNode } from '../../lib/filterNode';

const InheritedBoardFiltersSection = () => {
  const filterState = useContext(FilterContext);
  const [hasFilters, setHasFilters] = useState(false);
  useEffect(() => {
    const filterNode = new FilterNode(filterState);
    if (filterNode.getAppliedFilterFields().length > 0) {
      setHasFilters(true);
    }
  }, [filterState.filterConsumable]);

  if (!hasFilters) return <div />;

  return (
    <div className="w-full p-4 rounded-md border border-gray-300 ">
      <div className="flex flex-col gap-y-3">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-x-1 items-center">
              <AdjustmentsHorizontalIcon className="h-5 w-5 stroke-2" />
              <h1 className="text-lg font-semibold text-blueberry">Inherited Filters</h1>
            </div>
            {/* To implement during polish */}
            {/*  <Toggle initialState={true} onSwitch={toggleBoardFilters} /> */}
          </div>
          <p className="text-gray-600 font-light text-sm">These filters come from the Board this chart belongs to.</p>
        </div>
        <div className="overflow-x-auto">
          <DisabledFilterDisplay filter={filterState.appliedFilter} />
        </div>
      </div>
    </div>
  );
};

export default InheritedBoardFiltersSection;
