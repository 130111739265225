import { useContext, useEffect, useState } from "react";
import { CheckmarkIcon } from "react-hot-toast";
import { FilterContext, FilterDispatchContext } from "../../../../context/filterStatementContext";
import { FilterField, FilterFieldValue, FilterType, Operator, useGetFilterFieldValuesLazyQuery } from "../../../../generated/graphql";
import { FilterGroupActionType, FilterNodeSchema, FilterNodeState } from "../../../../reducers/filterStatement/filterStatementReducer";
import { useValidTeamAppContext } from "../../../../v2/contexts/AppContext";
import Button, { ButtonSize, ButtonVariant } from "../../../baseComponents/Button";
import DropDown from "../../../baseComponents/DropDown";
import LoadingSpinner from "../../../baseComponents/LoadingSpinner";
import { adaptForFilterNode, normalizeOperator } from "../operatorUtils";


const operatorSupportsMultipleValues = (operator: Operator) => {
    return [Operator.In, Operator.NotIn].includes(operator);
};

export const FieldValueConfiguration = ({ 
    selectedField, 
    resetFunction,
    currentNode, 
}: { 
    selectedField: FilterField, 
    resetFunction: () => void, 
    currentNode: FilterNodeState | undefined, 
}) => {

    const [selectedOperator, setSelectedOperator] = useState<Operator | null>(selectedField.allowedOperators[0]);
    const [selectedValues, setSelectedValues] = useState<FilterFieldValue[]>([]);

    const { curTeamId } = useValidTeamAppContext();
    const { filterConsumable } = useContext(FilterContext);

    const [getFilterFieldValues, { data, loading }] = useGetFilterFieldValuesLazyQuery();

    /**
     * When we have a currentNode, then we are NOT adding to the top level. Rather, we are expanding on an existing filter.
     * This means we are adding filters within a gray bubble...
     * That existing filter can either be a statement or a collection.
     * If it's a collection, we need to add the new statement to the collection.
     * If it's a statement, we need to replace it with a collection containing the old statement and the new statement.
     */
    function addNestedFilter() {
        if (!currentNode) return;

        const newStatement: FilterNodeSchema = {
            type: 'statement',
            fieldName: selectedField.fieldName,
            operator: adaptForFilterNode(selectedOperator || Operator.Equal),
            value: selectedValues.length > 1 ? JSON.stringify(selectedValues.map((value) => value.filterValue)) : selectedValues[0].filterValue,
            fieldDisplayName: selectedField.displayName,
            valueDisplayName: selectedValues.length > 1 ? selectedValues.map((value) => value.displayName).join(', ') : selectedValues[0].displayName,
        }

        const payload = (currentNode.type === 'collection') ? {
            id: currentNode.id,
            filterNode: {
                ...currentNode,
                items: [...currentNode.items, newStatement]
            }
        } : {
            id: currentNode.id,
            filterNode: {
                type: 'collection',
                operator: FilterType.And,
                items: [currentNode, newStatement],
            },
        };

        dispatch({
            type: FilterGroupActionType.UpdateFilterNode,
            payload,
        });
    }

    useEffect(() => {
        setSelectedValues([]);
        setSelectedOperator(null);
        if(selectedField) {
          getFilterFieldValues({
            variables: {
              fieldName: selectedField.fieldName,
              teamId: curTeamId,
              fieldStatementFilters: filterConsumable,
              skip: 0,
              take: 100,
            }
          });
        }
      }, [selectedField, filterConsumable]);

    const dispatch = useContext(FilterDispatchContext);
    
    return (
        <div className="flex w-full flex-col p-4 bg-silver max-h-[398px] overflow-y-scroll rounded-r-lg">
          <div className="text-blueberry-light font-semibold mt-2 mb-5">{selectedField.displayName}</div>
          {loading ? (
            <div className="flex flex-col items-center justify-center py-10">
              <div className="flex-row">
                <LoadingSpinner />
              </div>
              <div className="text-blueberry-light flex-row text-sm py-2 animate-pulse">Loading filter configuration...</div>
            </div>
          ) : (
            <>
              <DropDown
                setSelectedItem={(item) => {
                  setSelectedOperator(item.value as Operator);
                }}
                selectedItem={{
                  name: normalizeOperator(selectedOperator || Operator.Equal),
                  id: selectedOperator || '',
                  displayName: normalizeOperator(selectedOperator || Operator.Equal),
                  value: selectedOperator || Operator.Equal,
                }}
                dropDownData={selectedField?.allowedOperators.map((operator) => ({
                  name: normalizeOperator(operator),
                  id: operator,
                  displayName: normalizeOperator(operator),
                  value: operator,
                }))}
              />
              <section className="flex flex-col max-h-[80%] overflow-y-scroll">
                {data?.getFilterFieldValues?.length ? (
                  <div className="flex flex-col mt-3 mb-3">
                    {data.getFilterFieldValues.map((value) => (
                      <div
                        className={`flex flex-row justify-between vertical-align-middle text-blueberry-light text-sm bg-gray-100 border border-gray-200 p-2 my-1 rounded-md cursor-pointer hover:bg-gray-200 transition-all duration-200 ${
                          selectedValues.some((selectedValue) => selectedValue.displayName === value.displayName) ? 'bg-gray-200 border-gray-200' : ''
                        }`}
                        onClick={() => {
                          if (selectedValues.some((selectedValue) => selectedValue.filterValue === value.filterValue)) {
                            setSelectedValues(selectedValues.filter((selectedValue) => selectedValue.filterValue !== value.filterValue));
                          } else {
                            if (operatorSupportsMultipleValues(selectedOperator || Operator.Equal)) {
                              setSelectedValues([...selectedValues,
                                value]);
                            } else {
                              setSelectedValues([value]);
                            }
                          }
                        }}
                      >
                        <p className="max-w-[200px] overflow-ellipsis">{value.displayName}</p>
                        <div className="flex flex-col justify-center">
                          <div
                            className={`flex flex-center text-gray-500 font-medium ${
                              selectedValues.some((selectedValue) => selectedValue.filterValue === value.filterValue) ? 'visible' : 'invisible'
                            }`}
                          >
                            <CheckmarkIcon className="w-4 h-4 vertical-align-middle" primary="gray-500" secondary="gray-500" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center py-10">
                    <p className="text-gray-400 text-sm italic">No values found</p>
                  </div>
                )}
              </section>
              <div className="flex flex-row justify-between pt-3 mt-auto border-t border-gray-200 -ml-4 -mr-4 px-4">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Tertiary}
                  text="Cancel"
                  onClick={resetFunction}
                />
                <Button
                  size={ButtonSize.Small}
                  text="Apply"
                  disabled={selectedValues.length === 0}
                  onClick={() => {
                    if (currentNode) {
                      addNestedFilter();
                    } else {
                        // This just adds to the top level filter. This will add another gray bubble to the filter bar.
                        dispatch({
                            type: FilterGroupActionType.AddFilterNode,
                            payload: {
                                filterNode: {
                                    type: 'statement',
                                    fieldName: selectedField.fieldName,
                                    operator: adaptForFilterNode(selectedOperator || Operator.Equal),
                                    value: selectedValues.length > 1 ? JSON.stringify(selectedValues.map((value) => value.filterValue)) : selectedValues[0].filterValue,
                                    fieldDisplayName: selectedField.displayName,
                                    valueDisplayName: selectedValues.length > 1 ? selectedValues.map((value) => value.displayName).join(', ') : selectedValues[0].displayName,
                                }
                            },
                        });
                    }
                    resetFunction();
                }}
                />
              </div>
            </>
          )}
        </div>
    )
}
