import { useContext, useEffect, useState } from 'react';

import { FilterContext } from '../../../../context/filterStatementContext';
import { FilterConfigurationPopup } from '../filterConfiguration/FilterConfiguration';
import { RootFilter } from './RootFilter';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';

/**
 * The filter bar presents filter configuration to the user in a minimalistic way by progressively revealing the configuration options.
 * The user can select a field, an operator, and then add values to the filter.
 * The filter configuration is then saved to the filter statement tree.
 *
 * The filter bar needs to know what filters are currently applied so that it can exclude them from the list of available fields.
 * The filter bar should always be rendered in a component tree that is wrapped in a FilterablePage HOC.
 */
const FilterBarWrapper = () => {
  const { filterConsumable } = useContext(FilterContext);
  const { curTeamId, curOrgId } = useValidTeamAppContext();

  useEffect(() => {
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);

    urlParams.set('teamId', curTeamId.toString());
    urlParams.set('orgId', curOrgId.toString());
    urlParams.set('filterConsumable', filterConsumable);

    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }, [filterConsumable, curTeamId, curOrgId]);

  return <FilterBar />;
};

export const FilterBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { appliedFilter } = useContext(FilterContext);

  return (
    <div className="relative">
      <div className="flex flex-row gap-x-2 items-center relative">
        <RootFilter filter={appliedFilter} setIsOpen={setIsOpen} />
        <FilterConfigurationPopup appliedFilter={appliedFilter} isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
};

export default FilterBarWrapper;
