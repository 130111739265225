import { useEffect, useState } from 'react';
import { FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { AppliedFilterActions } from './AppliedFilterActions';
import { AppliedFilterEditor } from './AppliedFilterEditor';
import { adaptForDisplay } from '../operatorUtils';
import Tippy from '@tippyjs/react';

export const AppliedStatementFilter: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  if (filter.type !== 'statement') throw new Error('AppliedStatementFilter must be a statement');

  const [displayName, setDisplayName] = useState('');
  const [displayValue, setDisplayValue] = useState('');
  const [showFilterStatementEditor, setShowFilterStatementEditor] = useState(false);

  useEffect(() => {
    setDisplayValue(filter.valueDisplayName ?? filter.value); // how do we load these in from saved filters or the url?
    setDisplayName(filter.fieldDisplayName ?? filter.fieldName);
  }, [filter.value]);

  return (
    <Tippy
      className="pointer-events-auto" // this allows the tooltip to be interacted with even if the filter is disabled
      content={
        <div>
          <span className="mr-1 text-white ">{displayName}</span>
          <strong className="text-gray-400">
            {adaptForDisplay(filter.operator)} <span className="text-white">{displayValue}</span>
          </strong>
        </div>
      }
    >
      <div>
        <button
          className="text-left pr-10 lg:pr-0 lg:whitespace-nowrap hover:*:text-raspberry hover:cursor-pointer max-w-[11vw] overflow-hidden text-ellipsis"
          onClick={() => {
            setShowFilterStatementEditor(true);
          }}
        >
          <span className="mr-1 text-gray-500 ">{displayName}</span>
          <strong className="text-blueberry ">
            {adaptForDisplay(filter.operator)} {displayValue}
          </strong>
        </button>
        <AppliedFilterActions filter={filter} />
        {showFilterStatementEditor ? <AppliedFilterEditor filterStatement={filter} setOpen={setShowFilterStatementEditor} /> : null}
      </div>
    </Tippy>
  );
};
