import { FilterInput } from '../../generated/graphql';
import { DataFormat } from '../../exports/exporters/DataFormat';
import { ProgressState, Status } from '../../exports/ProgressMonitor';
import { localDateString } from '../../v2/util';
import { listenForFileDownload, updateProgressOnInterval } from './progressBar';
import { EntryExporter } from '../../exports/exporters/EntryExporter';
import { getExportErrorHandler } from './errorHandling';
import { FilterState } from '../../reducers/filterStatement/filterStatementReducer';

export const exportFeedbackWithProgressUpdate = (
  params: {
    teamId: number;
    filterStateConsumable: string;
  },
  exportProgress: ProgressState,
  updateExportProgress: React.Dispatch<React.SetStateAction<ProgressState>>
) => {
  const report = EntryExporter.getInstance({
    teamId: params.teamId,
    filterStateConsumable: params.filterStateConsumable,
  });

  const fileName = `Unwrap Feedback Export - ${localDateString(new Date(), 'YYYY-MM-DD HH:MM:SS Z')}`;

  const observer = listenForFileDownload(fileName, updateExportProgress);
  const timeout = updateProgressOnInterval(report, updateExportProgress);

  /**
   * When the export promise is resolved, we only update the bar to 90%
   * We do this because it takes some time for the CSV library to generate the report and download it to the browser.
   * Without this change, the bar will hang at 100% and appear as bug to the end user
   */
  report
    .export(fileName, DataFormat.CSV)
    .catch(getExportErrorHandler({ teamId: params.teamId, groupIds: [] }, exportProgress, observer, updateExportProgress))
    .finally(() => {
      window.clearInterval(timeout);
      updateExportProgress({ status: Status.finished, percent: 90 });
    });
};
