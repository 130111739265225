import uuid from 'react-uuid';
import { Date_Window, FilterInput, FilterType, Operator } from '../../../generated/graphql';
import { computeFilterConsumable, FilterNodeState, FilterState, FilterStatementState } from '../../../reducers/filterStatement/filterStatementReducer';
import { DefaultDateConfig, TeamDefaultConfigs } from '../../../TeamDefaultConfigs';
import { DateFilterUtility } from './DateFilterUtility';
import { FilterTree } from '../../lib/filterTree';

export class InitialFilterStateProvider {
  /**
   * Get the initial filter state for a given team.
   *
   * This is a waterfall method that will return the default filter state for a given team
   * 1. Get any filters set from the url params -> if exists, return filter state derived from url params
   * 2. Get any default filters set for the team -> if exists, return filter state derived from team default configs
   * 3. Return a default filter state object that contains only a 90 day date filter applied.
   *
   * @param teamId The team id to get the default filter state for.
   * @returns The default filter state for the given team.
   */
  public static getInitialFilterState(teamId: number): FilterState {
    const initialState = InitialFilterStateProvider._getInitialState(teamId);

    /**
     * Repopulate the cache so that the next call to getTeamDefaultValues has the latest values.
     *
     * This is explicitly called non-awaited because I don't want to block the rendering thread waiting for this
     * to complete.
     */
    TeamDefaultConfigs.populateCache();

    return initialState;
  }

  public static getStateFromFilterNode(filterNode: string): FilterState {
    const parsed = JSON.parse(filterNode) as FilterNodeState;
    const filterTree = new FilterTree(parsed);
    const staticConditions = (filterTree.getStaticConditions() as FilterStatementState[]) ?? InitialFilterStateProvider.getDefaultState().staticConditions;
    const appliedFilter = filterTree.getAppliedFilters() ?? InitialFilterStateProvider.getDefaultAppliedFilter();

    const state = {
      appliedFilter,
      staticConditions,
      filterConsumable: computeFilterConsumable(staticConditions, appliedFilter),
    };

    return state;
  }

  private static _getInitialState(teamId: number): FilterState {
    const urlParams = new URLSearchParams(window.location.search);
    const groupParam = urlParams.get('group');
    const filterConsumable = urlParams.get('filterConsumable');

    try {
      // If the filterConsumable is present, we need to parse it and use it to set the initial state
      // this should take precedence over the old contract.
      if (filterConsumable) {
        const parsed = JSON.parse(decodeURIComponent(filterConsumable) ?? '{}') as FilterNodeState;
        const filterTree = new FilterTree(parsed);
        // mmm this is a good point... how do we know what the static conditions are?
        // should we encode the static conditions in the url params? and not write the filterConsumable as the url param?
        const staticConditions = (filterTree.getStaticConditions() as FilterStatementState[]) ?? InitialFilterStateProvider.getDefaultState().staticConditions;
        const appliedFilter = filterTree.getAppliedFilters() ?? InitialFilterStateProvider.getDefaultAppliedFilter();

        return {
          appliedFilter,
          staticConditions,
          filterConsumable,
        };
      }
    } catch (e) {
      console.error('Error parsing filter consumable', e);
      console.error('Invalid filter consumable', filterConsumable);
    }

    // No filter consumable is in the URL params. Either parse the old filter contract or use the default date filter.
    if (groupParam) {
      return InitialFilterStateProvider.parseOldFilterContractFromUrlParams(groupParam);
    }

    // No old filter contract in the URL params. Use the default date filter.
    const defaultConfig = TeamDefaultConfigs.getTeamDefaultValues(teamId);
    if (defaultConfig) {
      return InitialFilterStateProvider.getDefaultDateFilter(defaultConfig);
    }

    return InitialFilterStateProvider.getDefaultState();
  }

  /**
   * How we use the default date configs:
   *
   * - If the team has both a start and end date, set the date selector to those dates and ignore the window.
   * - If either start or end dates are missing, and the team has a default window, set the date selector to that window.
   * - If the team has no default window or dates, do nothing.
   * @param defaultConfig
   * @returns
   */
  private static getDefaultDateFilter(defaultConfig: DefaultDateConfig): FilterState {
    const defaultWindow = defaultConfig.exploreDefaultWindow;
    if (defaultConfig.startDate && defaultConfig.endDate) {
      return this.getDateRangeState(new Date(defaultConfig.startDate), new Date(defaultConfig.endDate));
    } else if (defaultWindow) {
      return this.getDateWindowState(defaultWindow);
    }
    return InitialFilterStateProvider.getDefaultState();
  }

  private static getDateWindowState(defaultWindow: Date_Window): FilterState {
    let startDate: Date | undefined;
    const staticConditions: FilterStatementState[] = [];
    switch (defaultWindow) {
      case Date_Window.Last_7d:
        startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        break;
      case Date_Window.Last_30d:
        startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
        break;
      case Date_Window.Last_90d:
        startDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
        break;
      case Date_Window.Last_1y:
        startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
        break;
      default:
        break;
    }
    if (startDate) {
      staticConditions.push(
        ...DateFilterUtility.getDateFilterStatement({ start: startDate }).map((node) => ({
          ...node,
          id: uuid(),
        }))
      );
    }

    return this.getStateGivenStaticConditions(staticConditions);
  }

  private static getDateRangeState(startDate: Date, endDate: Date): FilterState {
    const staticConditions = DateFilterUtility.getDateFilterStatement({ start: startDate, end: endDate }).map((node) => ({
      ...node,
      id: uuid(),
    }));

    return this.getStateGivenStaticConditions(staticConditions);
  }

  private static getStateGivenStaticConditions(staticConditions: FilterStatementState[]): FilterState {
    return {
      staticConditions,
      appliedFilter: InitialFilterStateProvider.getDefaultAppliedFilter(),
      filterConsumable: computeFilterConsumable(staticConditions, InitialFilterStateProvider.getDefaultAppliedFilter()),
    };
  }

  private static getDefaultAppliedFilter(): FilterNodeState {
    return {
      type: 'collection',
      operator: FilterType.And,
      items: [],
      id: uuid(),
    };
  }

  private static getDefaultState(): FilterState {
    const defaultStartDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);

    const dateFilter: FilterStatementState = {
      type: 'statement',
      fieldName: 'Entry.date',
      operator: '>=',
      value: defaultStartDate.toISOString(),
      id: uuid(),
    };

    return {
      appliedFilter: InitialFilterStateProvider.getDefaultAppliedFilter(),
      staticConditions: [dateFilter],
      filterConsumable: computeFilterConsumable([dateFilter], undefined),
    };
  }

  /**
   * Parse the old filter contract from the url params.
   * these are the only filters that are supported in the old filter contract
   * ** Entry.date >= startDate
   * startDate?: InputMaybe<Scalars['Date']>;
   * ** Entry.date <= endDate
   * endDate?: InputMaybe<Scalars['Date']>;
   * ** Entry.id = entryId
   * entryFilter?: InputMaybe<Array<EntryFilterInput>>;
   * ** Entry.Group.id = groupId
   * groupFilter?: InputMaybe<Array<GroupFilterInput>>;
   * ** Entry.Segment.<segmentGroupId>.value = segmentId
   * segmentFilters?: InputMaybe<Array<FeedbackSegmentFilterInput>>;
   * @param groupParam The url params to parse.
   *
   * @returns The parsed filter state.
   */
  public static parseOldFilterContractFromUrlParams(groupParam: string): FilterState {
    const parsed = JSON.parse(decodeURIComponent(groupParam) ?? '{}') as FilterInput;
    if (!parsed) {
      return {
        staticConditions: [],
        appliedFilter: InitialFilterStateProvider.getDefaultAppliedFilter(),
        filterConsumable: computeFilterConsumable([], InitialFilterStateProvider.getDefaultAppliedFilter()),
      };
    }
    const staticConditions: FilterStatementState[] = [];
    staticConditions.push(
      ...DateFilterUtility.getDateFilterStatement({
        start: parsed.startDate ? new Date(parsed.startDate) : undefined,
        end: parsed.endDate ? new Date(parsed.endDate) : undefined,
      }).map((node) => ({
        ...node,
        id: uuid(),
      }))
    );

    let appliedFilter: FilterNodeState = {
      id: uuid(),
      type: 'collection',
      operator: 'AND',
      items: [],
    };

    if (parsed.entryFilter && parsed.entryFilter.length > 0 && parsed.entryFilter[0].entry.length > 0) {
      appliedFilter.items.push(
        ...parsed.entryFilter[0].entry.map(
          (entryFilter): FilterStatementState => ({
            type: 'statement',
            fieldName: 'Entry.id',
            operator: '==' as Operator,
            value: entryFilter.id,
            id: uuid(),
          })
        )
      );
    }

    if (parsed.groupFilter && parsed.groupFilter.length > 0 && parsed.groupFilter[0].group.length > 0 && parsed.groupFilter[0].group[0].id) {
      appliedFilter.items.push(
        ...parsed.groupFilter[0].group.map(
          (groupFilter): FilterStatementState => ({
            type: 'statement',
            fieldName: 'Entry.Group.id',
            operator: '==' as Operator,
            value: groupFilter.id!,
            id: uuid(),
          })
        )
      );
    }

    if (parsed.segmentFilters && parsed.segmentFilters.length > 0 && parsed.segmentFilters[0].segments.length > 0) {
      appliedFilter.items.push(
        ...parsed.segmentFilters.flatMap((segmentFilter) =>
          segmentFilter.segments.map(
            (segment): FilterStatementState => ({
              type: 'statement',
              fieldName: `Entry.Segment.${segmentFilter.groupId}.value`,
              operator: '==' as Operator,
              value: segment,
              id: uuid(),
            })
          )
        )
      );
    }

    if (appliedFilter.items.length === 0) {
      appliedFilter = InitialFilterStateProvider.getDefaultAppliedFilter();
    }

    return {
      staticConditions,
      appliedFilter,
      filterConsumable: computeFilterConsumable(staticConditions, appliedFilter),
    };
  }
}
