import { client } from './client';
import { Date_Window, TeamDefaultConfigsDocument, TeamDefaultConfigsQuery } from './generated/graphql';

export type DefaultDateConfig = {
  endDate?: string | null;
  startDate?: string | null;
  exploreDefaultWindow?: Date_Window | null;
  teamId: number;
};

export class TeamDefaultConfigs {
  private static CACHE_KEY = 'teamDefaultConfigs';
  /**
   * Get the default configs for a given team.
   * @param teamId The team id to get the default configs for.
   * @returns The default configs for the given team or undefined if not found meaning the team has no default configs set.
   */
  static getTeamDefaultValues(teamId: number): DefaultDateConfig | undefined {
    const cachedTeamDefaultConfigs = localStorage.getItem(TeamDefaultConfigs.CACHE_KEY);
    if (cachedTeamDefaultConfigs) {
      const parsedConfigs = JSON.parse(cachedTeamDefaultConfigs) as DefaultDateConfig[];
      return parsedConfigs.find((config) => config.teamId === teamId);
    }
    return undefined;
  }

  /**
   * Check cache for team default configs, if not found, fetch from server and set in cache and block until done.
   *
   * This loads in all default configs for all teams. This makes it so we don't have to know what org or team we're currently on and allows us to make this
   * call far up the stack instead of lower at the page level, without having to do much refactoring.
   */
  static async initializeTeamDefaultConfigs(): Promise<void> {
    const cachedTeamDefaultConfigs = localStorage.getItem(TeamDefaultConfigs.CACHE_KEY);
    if (cachedTeamDefaultConfigs) {
      return Promise.resolve();
    }

    await this.populateCache();
  }

  /**
   * Repopulate the cache with the latest team default configs.
   */
  static async populateCache(): Promise<void> {
    const { data } = await client.query<TeamDefaultConfigsQuery>({ query: TeamDefaultConfigsDocument });
    if (data) {
      localStorage.setItem(TeamDefaultConfigs.CACHE_KEY, JSON.stringify(data.teamDefaultConfigs));
    }
  }
}
