import { SearchInput } from '../../../baseComponents/SearchInput';
import { SavedFilterRow } from './SavedFilterRow';
import { FilterRow } from './FilterRow';
import { FilterField } from '../../../../generated/graphql';
import { Divider } from './Divider';

export const FieldDropdown = ({
  fields,
  setSelectedField,
  selectedField,
}: {
  fields: FilterField[];
  setSelectedField: (field: FilterField) => void;
  selectedField: FilterField | null;
}) => {
  return (
    <div className="flex flex-col max-h-[400px] overflow-y-scroll pb-2">
      <section className="flex-col gap-2">
        {/* <SearchInput
          noPadding
          rounded="md"
          className="pb-3 px-4 pt-4"
          placeholder={`Search Filters`}
          onSearch={() => {}}
          onChange={() => {
            console.log('stuff');
          }}
        /> */}
        {/* <SavedFilterRow /> */}
        <Divider label="Feedback" />
        {fields
          .filter((field) => field.hasValues)
          .map((field, idx) => (
            <FilterRow
              key={idx}
              selected={selectedField?.fieldName === field.fieldName}
              filterName={field.displayName}
              filterType={field.fieldName}
              onClick={() => {
                setSelectedField(field);
              }}
            />
          ))}
      </section>
    </div>
  );
};
