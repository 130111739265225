import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FilterInput, useDataForFiltersLazyQuery, useTeamDefaultsLazyQuery, Date_Window } from '../../../../generated/graphql';
import AppContext, { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import FilterModal from '../FilterModal';
import { IBadgeFilter, IFilter } from '../FiltersTypes';
import { HorizontalDateSelectorOption } from '../../../components/HorizontalDateSelector';
import { FilterHook, updateFilters, useFilterHook } from '../../../hooks/FilterHook';
import { DateOptions, FilterableDataTypes, FilterManagerDisplayMode, setUiFilters, shouldShowFilterComponents } from '../FiltersUtil';
import SearchInput from '../../../baseComponents/SearchInput';
import { classNames, useIsMount } from '../../../../v2/util';
import { Status } from '../../../../exports/ProgressMonitor';
import { ProgressBar } from '../../../components/ProgressBar';
import { FiltersShownList } from './sections/FiltersShownList';
import { DateFilters } from './sections/DateFilters';
import { ExportToCSV } from './sections/ExportToCSV';

interface FilterManagerProps {
  pageName: string;
  filterHook?: FilterHook;
  setFilterInputs?: (filterInput: FilterInput) => void;
  dataTypeToFilter: FilterableDataTypes;
  searchEnabled?: boolean;
  queryStringAppliesToGroupTitle?: boolean;
  displayMode?: FilterManagerDisplayMode;
  filterButtonText?: string;
  teamIdOverride?: number;
  startingFilterInput?: FilterInput;
  overridenFiltersShown?: IFilter[];
  parentSetFiltersShown?: (filters: IFilter[]) => void;
  disableFilterEditing?: boolean;
}

export const FilterManager = forwardRef(
  (
    {
      pageName,
      filterHook,
      dataTypeToFilter,
      queryStringAppliesToGroupTitle,
      displayMode = FilterManagerDisplayMode.Regular,
      filterButtonText,
      setFilterInputs,
      teamIdOverride,
      startingFilterInput,
      //This needs to be done to be able to share this array in some places.
      //Once this gets reworked into better Manager/Hook structure, this will be removed.
      overridenFiltersShown,
      parentSetFiltersShown,
      disableFilterEditing,
    }: FilterManagerProps,
    ref
  ): JSX.Element => {
    const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
    const defaultFilterHook = useFilterHook({ teamId, orgId, startingFilterInput: startingFilterInput ?? {}, disableUrlFilters: true });
    const hook = filterHook ? filterHook : defaultFilterHook;
    const filters = hook.filters;
    const [currentFilter, setCurrentFilter] = useState<IFilter | undefined>(undefined);
    const [filtersShown, setFiltersShown] = useState<IFilter[]>(overridenFiltersShown ?? []);
    const [modalOpen, setModalOpen] = useState(false);
    const [queryString, setQueryString] = useState<string | undefined>(
      queryStringAppliesToGroupTitle && filters?.groupTitleFilterQuery ? filters?.groupTitleFilterQuery : filters?.queryString?.[0] ?? undefined
    );
    const [getDataForFilters] = useDataForFiltersLazyQuery({ variables: { teamId: teamIdOverride ?? teamId, orgId } });
    const { app } = useContext(AppContext);
    const dateSelectorRef = useRef<any>(null);
    const [dateOptions, setDateOptions] = useState<HorizontalDateSelectorOption[]>(DateOptions);
    const urlDateFiltersExist = !!hook.filtersFromUrl?.startDate || !!hook.filtersFromUrl?.endDate;

    const [getTeamDefaults, { data: teamDefaults }] = useTeamDefaultsLazyQuery({
      variables: { teamId },
      onCompleted(data) {
        /*
        Team Defaults Logic:
        - The oldestFeedbackDate is used to determine the value of the "All" date option.
        - Regarding the default date logic:
          - If the team has both a start and end date, set the date selector to those dates and ignore the window.
          - If the start, end, or both dates are missing, and the team has a default window, set the date selector to that window.
          - If the team has no default window or dates, do nothing.
        URL Date Filters override these defaults, as we need to obey what's on the URL.
        This logic is applied to the Explore, Feedback, and Charts pages.
        The Group Page inherits the filters from the Explore Page even when coming from a separate URL, so groups follow the same logic. 
        Applying this to the Group Page would override inherited filters and cause conflict, so we need the condition, at least until a future rework.
        */

        //Setting the ALL date option to the oldest feedback date.
        const newDateOptions = [
          ...dateOptions.map((option) => {
            return option.name === 'All' ? { ...option, startDate: moment.utc(data.teamDefaults.oldestFeedbackDate).toDate(), disabled: false } : option;
          }),
        ];
        setDateOptions(newDateOptions);

        const defStartDate = data.teamDefaults.startDate;
        const defEndDate = data.teamDefaults.endDate;
        if (!urlDateFiltersExist) {
          if (pageName === 'Explore Page' || pageName === 'Feedback Page' || pageName === 'Charts Page' || pageName === 'Board Page') {
            if (defStartDate && defEndDate) {
              const startDt = moment.utc(defStartDate).toDate();
              const endDt = moment.utc(defEndDate).toDate();
              updateClustersDates(startDt, endDt);
              dateSelectorRef.current?.updateSelectedOption(startDt, endDt);
            } else {
              //We omit the "ALL" option, as it's handled on a separate useEffect once the ALL selector's state has been populated and becomes available.
              if (data.teamDefaults.exploreDefaultWindow && data.teamDefaults.exploreDefaultWindow !== Date_Window.All) {
                dateSelectorRef.current?.setOptionByDateWindow(data.teamDefaults.exploreDefaultWindow);
              }
            }
          }
        }
      },
    });

    function updateFiltersShown(filters: IFilter[]) {
      parentSetFiltersShown?.(filters);
      if (!overridenFiltersShown) {
        if (filtersShown.length === 0 && filters.length === 0) return; //Dont update if both are empty.
        setFiltersShown(filters);
      }
    }

    useEffect(() => {
      setFiltersShown(overridenFiltersShown ?? []);
    }, [overridenFiltersShown]);

    useEffect(() => {
      //If dateOptions got "All" updated and the team has an "All" default window, set the date selector to that window.
      if (
        !urlDateFiltersExist &&
        dateSelectorRef.current &&
        !dateOptions.find((option) => option.name === 'All')?.disabled &&
        teamDefaults?.teamDefaults?.exploreDefaultWindow === Date_Window.All &&
        !teamDefaults?.teamDefaults?.startDate &&
        !teamDefaults?.teamDefaults?.endDate
      ) {
        dateSelectorRef.current.setOptionByDateWindow(teamDefaults.teamDefaults.exploreDefaultWindow);
      }
    }, [dateOptions]);

    const isFirstRender = useIsMount();

    useEffect(() => {
      let isMount = true;
      if (isMount) {
        setUiFilters(filters, updateFiltersShown, () => getDataForFilters({ variables: { teamId: teamIdOverride ?? teamId, orgId } }));
        getTeamDefaults();
      }
      return () => {
        isMount = false;
      };
    }, [teamIdOverride]);

    useEffect(() => {
      getTeamDefaults({ variables: { teamId } });
      if (!isFirstRender && dateSelectorRef.current) {
        dateSelectorRef.current.reset();
        setDateOptions((dateOptions) => dateOptions.map((option) => (option.name === 'All' ? { ...option, disabled: true } : option)));
      }
      if (!isFirstRender) setQueryString(undefined);
      updateFiltersShown([]);
    }, [teamId]);

    useEffect(() => {
      getTeamDefaults({ variables: { teamId } });
    }, []);
    useImperativeHandle(ref, () => ({
      refreshUiFilters: (filters: FilterInput) => {
        setUiFilters(filters, updateFiltersShown, () => getDataForFilters({ variables: { teamId: teamIdOverride ?? teamId, orgId } }));
      },
    }));

    const openModal = () => setModalOpen(true);

    const onAddFilter = () => {
      setCurrentFilter(undefined);
      openModal();
    };

    const onEditFilter = (badge: IBadgeFilter) => {
      setCurrentFilter(badge.filter);
      openModal();
    };

    const saveFilter = (filter: IFilter, teamId: number) => {
      const existingIndex = filtersShown.findIndex((existing) => {
        return existing.uiId === filter.uiId;
      });

      if (existingIndex > -1) {
        filtersShown.splice(existingIndex, 1);
      }
      const newFilters = [...filtersShown];
      if (filter.values.length !== 0) {
        newFilters.push(filter);
      }

      updateFilters(
        teamId,
        orgId,
        newFilters,
        filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
        filters.startDate,
        filters.endDate,
        queryString,
        'group',
        queryStringAppliesToGroupTitle,
        hook.disableUrlFilters
      );
      updateFiltersShown(newFilters);
    };

    const onRemoveFilter = (badge: IBadgeFilter, teamId: number): void => {
      const index = filtersShown.findIndex((filter) => {
        return filter === badge.filter;
      });
      filtersShown.splice(index, 1);
      updateFiltersShown([...filtersShown]);
      updateFilters(
        teamId,
        orgId,
        filtersShown,
        filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
        filters.startDate,
        filters.endDate,
        queryString,
        'group',
        queryStringAppliesToGroupTitle,
        hook.disableUrlFilters
      );
    };

    const updateClustersDates = (newStartDate: Date = filters.startDate, newEndDate: Date = filters.endDate) => {
      updateFilters(
        teamId,
        orgId,
        filtersShown,
        filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
        newStartDate,
        moment(newEndDate).endOf('day').toDate(),
        queryString,
        'group',
        queryStringAppliesToGroupTitle,
        hook.disableUrlFilters
      );
    };

    const [exportProgress, updateExportProgress] = useState({ status: Status.idle, percent: 0 });
    //if (loadingTeamDefaults) return <></>;
    return (
      <div>
        {!app?.isPreviewMode && (
          <FilterModal
            teamIdOverride={teamIdOverride}
            currentFilter={currentFilter}
            modalOpen={modalOpen}
            setCurrentFilter={setCurrentFilter}
            setModalOpen={setModalOpen}
            saveFilter={saveFilter}
            page={pageName}
            dataTypeToFilter={dataTypeToFilter}
          />
        )}
        <div className="flex flex-col gap-y-0.5">
          <div className="flex flex-row items-center justify-between gap-x-0.5">
            {shouldShowFilterComponents('search', displayMode) ? (
              <div className="relative hidden w-1/4 flex-col items-center xl:flex">
                <SearchInput
                  locked={displayMode === FilterManagerDisplayMode.ChartsPage}
                  queryString={queryString}
                  setQueryString={setQueryString}
                  onSearch={(query) => {
                    updateFilters(
                      teamId,
                      orgId,
                      filtersShown,
                      filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
                      filters.startDate,
                      filters.endDate,
                      query,
                      'group',
                      queryStringAppliesToGroupTitle,
                      hook.disableUrlFilters
                    );
                  }}
                  noPadding
                />
              </div>
            ) : null}
            <div className="flex flex-row gap-x-1">
              {shouldShowFilterComponents('dates', displayMode) ? (
                <DateFilters
                  filters={filters}
                  dateOptions={dateOptions}
                  updateClustersDates={updateClustersDates}
                  dateSelectorRef={dateSelectorRef}
                  filtersShown={filtersShown}
                  hook={hook}
                  queryStringAppliesToGroupTitle={queryStringAppliesToGroupTitle}
                />
              ) : null}

              {shouldShowFilterComponents('filterButton', displayMode) ? (
                <FilterButton onAddFilter={onAddFilter} active={filtersShown.length > 0} filterButtonText={filterButtonText} />
              ) : null}
              {/* {shouldShowFilterComponents('exportToCSV', displayMode) ? (
                <ExportToCSV teamId={teamId} filterInput={filters} exportProgress={exportProgress} updateExportProgress={updateExportProgress} />
              ) : null} */}
            </div>
          </div>
          {shouldShowFilterComponents('search', displayMode) ? (
            <div className="block w-1/2 xl:hidden xl:w-1/3">
              <SearchInput
                queryString={queryString}
                setQueryString={setQueryString}
                onSearch={(query) => {
                  updateFilters(
                    teamId,
                    orgId,
                    filtersShown,
                    filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
                    filters.startDate,
                    filters.endDate,
                    query,
                    'group',
                    queryStringAppliesToGroupTitle,
                    hook.disableUrlFilters
                  );
                }}
                noPadding
              />
            </div>
          ) : null}

          {shouldShowFilterComponents('filtersShown', displayMode) ? (
            <FiltersShownList
              filtersShown={filtersShown}
              onEditFilter={onEditFilter}
              onRemoveFilter={onRemoveFilter}
              teamId={teamId}
              disableFilterEditing={!!disableFilterEditing}
            />
          ) : null}
        </div>
        {/* {exportProgress.status !== Status.idle ? (
          <div className="flex flex-col items-center justify-center py-2.5">
            <ProgressBar exportProgress={exportProgress} />
          </div> 
        ) : null} */}
      </div>
    );
  }
);

const FilterButton = ({ onAddFilter, active, filterButtonText }: { onAddFilter: () => void; active: boolean; filterButtonText?: string }) => {
  return (
    <div
      id="filter-modal-opener"
      className={classNames(
        active ? 'bg-blueberry text-white hover:bg-blueberry-lighter' : 'bg-silver hover:bg-silver-darker text-blueberry',
        'flex h-full cursor-pointer items-center gap-x-1 rounded-full py-2 px-3 duration-150'
      )}
      onClick={() => onAddFilter()}
    >
      <AdjustmentsHorizontalIcon className="h-5 w-5" />
      <p>{filterButtonText ?? 'Filter'}</p>
    </div>
  );
};
