import { createContext, Dispatch, ReactNode, useReducer, useContext } from 'react';
import { ChartAction, ChartReducer, ChartState, getInitialPlotState } from '../reducers/charts/chartReducer';

// Create contexts with non-undefined default values
export const ChartContext = createContext<ChartState>(getInitialPlotState(-1));
export const ChartDispatchContext = createContext<Dispatch<ChartAction>>(() => {
  throw new Error('ChartDispatchContext must be used within a ChartProvider');
});

// Add custom hooks to enforce usage within provider
export const useChartState = () => {
  const context = useContext(ChartContext);
  if (context === undefined) {
    throw new Error('useChartState must be used within a ChartProvider');
  }
  return context;
};

export const useChartDispatch = () => {
  const context = useContext(ChartDispatchContext);
  if (context === undefined) {
    throw new Error('useChartDispatch must be used within a ChartProvider');
  }
  return context;
};

export const ChartProvider = ({ children, initialState }: { children: ReactNode; initialState: ChartState }) => {
  const [state, dispatch] = useReducer(ChartReducer, initialState);

  return (
    <ChartContext.Provider value={state}>
      <ChartDispatchContext.Provider value={dispatch}>{children}</ChartDispatchContext.Provider>
    </ChartContext.Provider>
  );
};
