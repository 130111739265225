import { useEffect } from 'react';
import { Breakdown, Chart_Type, useSegmentGroupLazyQuery, Y_Axis_Data } from '../../../generated/graphql';
import { useChartDispatch } from '../../../context/chartContext';
import { useChartState } from '../../../context/chartContext';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useState } from 'react';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { BreakdownMockList, ChartTypeList, PlotUnitMockList } from '../../pages/ChartsPage';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { ChartActionType } from '../../../reducers/charts/chartReducer';

const EditChartSettings = () => {
  const chartState = useChartState();
  const chartDispatch = useChartDispatch();
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [getFilterData, _] = useSegmentGroupLazyQuery({ variables: { teamId } });
  const [segments, setSegments] = useState<IDropDownItem[] | undefined>();

  useEffect(() => {
    getFilterData({
      onCompleted(data) {
        setSegments(
          data.segments?.map((segment) => {
            return { id: segment.id, name: segment.displayName };
          })
        );
      },
    });
  }, []);

  return (
    <div className="flex flex-row gap-x-4 mb-8 items-center">
      <EditChartInputDropdown name="Type">
        <div className="w-fit">
          <VirtualizedComboBox
            id="chart-type-dropdown"
            comboBoxData={ChartTypeList}
            grayStyle
            disableAlphabeticalSort
            disableClear
            setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
              if (!selectedItem) return;
              chartDispatch({ type: ChartActionType.UpdateChartType, payload: { type: selectedItem.value as Chart_Type } });
            }}
            selectedItem={ChartTypeList.find((item) => item.value === chartState.chartConfigs?.chartType)}
          />
        </div>
      </EditChartInputDropdown>
      <EditChartInputDropdown name="Plot Unit">
        <div className="w-60">
          <VirtualizedComboBox
            id="chart-plotunit-dropdown"
            comboBoxData={PlotUnitMockList}
            grayStyle
            disableAlphabeticalSort
            disableClear
            setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
              if (!selectedItem) return;

              chartDispatch({ type: ChartActionType.UpdateYAxisMetric, payload: { yAxisMetric: selectedItem.value as Y_Axis_Data } });
            }}
            selectedItem={PlotUnitMockList.find((item) => item.value === chartState.chartConfigs?.yAxisMetric)}
          />
        </div>
      </EditChartInputDropdown>
      {chartState.chartConfigs?.series.length === 1 && (
        <EditChartInputDropdown name="Breakdown">
          <div className="w-fit flex flex-row gap-x-2">
            <div>
              <VirtualizedComboBox
                id="chart-breakdown-dropdown"
                grayStyle
                disableAlphabeticalSort
                disableClear
                comboBoxData={segments && segments.length > 0 ? BreakdownMockList : BreakdownMockList.filter((b) => b.value !== Breakdown.Segment)}
                setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                  if (!selectedItem) return;
                  chartDispatch({ type: ChartActionType.UpdateBreakdown, payload: { breakdown: selectedItem.value as Breakdown } });
                }}
                selectedItem={BreakdownMockList.find((item) => item.value === chartState.chartConfigs?.breakdown)}
              />
            </div>
            {chartState.chartConfigs?.breakdown === Breakdown.Segment ? (
              <div className="w-fit">
                <VirtualizedComboBox
                  id="chart-segment-breakdown-dropdown"
                  grayStyle
                  disableAlphabeticalSort
                  disableClear
                  comboBoxData={segments ?? []}
                  setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                    if (!selectedItem) return;
                    chartDispatch({ type: ChartActionType.SetSegmentGroupId, payload: { segmentGroupId: Number(selectedItem.id) } });
                  }}
                  selectedItem={segments?.find((segment) => {
                    return segment.id === chartState.chartConfigs?.series?.[0].segmentGroupId;
                  })}
                />
              </div>
            ) : null}
          </div>
        </EditChartInputDropdown>
      )}
      <div className="flex-auto border-t-2 border-gray-300 mt-4"></div>
    </div>
  );
};
const EditChartInputDropdown = ({ name, children }: { name: string; children?: React.ReactNode; onAdd?: () => void; onRemove?: () => void }) => {
  return (
    <div className="flex flex-col">
      <p className="text-gray-600 italic text-sm">{name}</p>
      <>{children}</>
    </div>
  );
};

export default EditChartSettings;
