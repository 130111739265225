import Tippy from '@tippyjs/react';
import { useChartState } from '../../../context/chartContext';
import { useChartDispatch } from '../../../context/chartContext';
import { SmallSpinner } from '../SmallSpinner';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { ChartBinTypes } from '../../pages/ChartsPage';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import { Chart_Bin_Type } from '../../../generated/graphql';
import DateFilterComponent from '../filters/filterBar/DateFilterComponent';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';

export const ChartHeader = () => {
  const chartState = useChartState();
  const chartDispatch = useChartDispatch();
  const { curTeamId } = useValidTeamAppContext();
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row gap-x-4 items-center">
        <h1 className="text-lg font-semibold text-blueberry">Chart</h1>
      </div>
      {chartState.loadingChart ? (
        <div className="flex flex-row justify-center gap-x-1 px-2 rounded-md items-center text-blueberry">
          <SmallSpinner />
          <p>Loading...</p>
        </div>
      ) : null}
      <div className="flex flex-row justify-end gap-x-3">
        <Tippy theme="dark" content="Change the x-axis bin">
          <div className="w-28">
            <VirtualizedComboBox
              id="chart-bin-type-dropdown"
              grayStyle
              disableAlphabeticalSort
              disableClear
              selectedItem={ChartBinTypes.find((item) => item.value === chartState.chartConfigs?.binSelection)}
              comboBoxData={ChartBinTypes}
              setSelectedItem={(item) => {
                if (!item) return;
                if (item.value) {
                  chartDispatch({ type: ChartActionType.SetBinType, payload: { binType: item.value as Chart_Bin_Type } });
                }
              }}
            />
          </div>
        </Tippy>

        <DateFilterComponent teamId={curTeamId} />
      </div>
    </div>
  );
};

export default ChartHeader;
