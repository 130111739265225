import { useContext, useEffect, useState, useRef } from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { FilterContext, FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterGroupActionType } from '../../../../reducers/filterStatement/filterStatementReducer';
import { DatePicker } from '../../../baseComponents/DatePicker';
import { useGetEarliestFeedbackDateLazyQuery } from '../../../../generated/graphql';
import { DATE_RANGE_OPTIONS, DateFilterUtility, DateRangeOption } from '../../../filters/utilities/DateFilterUtility';

const DateFilterComponent = ({ teamId }: { teamId: number }) => {
  const filterState = useContext(FilterContext);
  const filterDispatch = useContext(FilterDispatchContext);

  const [startDate, setStartDate] = useState<Date | undefined>(DateFilterUtility.getStartDate(filterState));
  const endDate = DateFilterUtility.getEndDate(filterState);
  const selectedOption = DateFilterUtility.getSelectedOption(filterState);

  const [earliestFeedbackDateQuery, { loading: earliestFeedbackDateLoading }] = useGetEarliestFeedbackDateLazyQuery();

  useEffect(() => {
    if (selectedOption.label === 'All') {
      earliestFeedbackDateQuery({
        variables: {
          teamId: teamId,
        },
        onCompleted: (data) => {
          setStartDate(new Date(data.earliestFeedbackDate));
        },
      });
    }
  }, [selectedOption]);

  return (
    <div className="relative w-full lg:w-auto">
      <div className="flex items-center gap-2 rounded-full bg-gray-100 px-3 text-gray-700">
        <DateOptionDropdown />
        <span className="border-r border-gray-300 h-9 w-1 flex flex-shrink relative"></span>

        <span className="flex items-center">
          {earliestFeedbackDateLoading ? (
            <div className="w-auto flex justify-center items-center h-[34px]">
              <LoadingDots />
            </div>
          ) : (
            <div className="w-28" data-testid="start-date-picker">
              <DatePicker
                date={startDate}
                onChange={(dt: Date) => {
                  const payload = DateFilterUtility.getModifyDateFiltersPayload({ start: dt });
                  filterDispatch({ type: FilterGroupActionType.AlterStaticFilters, payload });
                  setStartDate(dt);
                }}
              />
            </div>
          )}
          -
          <div className="w-28" data-testid="end-date-picker">
            <DatePicker
              date={endDate}
              onChange={(dt: Date) => {
                const payload = DateFilterUtility.getModifyDateFiltersPayload({ end: dt });
                filterDispatch({ type: FilterGroupActionType.AlterStaticFilters, payload });
              }}
              placeholder="Today"
            />
          </div>
        </span>
      </div>
    </div>
  );
};

export const LoadingDots = ({ className = '' }: { className?: string }) => (
  <div className={`flex items-center gap-1 ${className}`} data-testid="loading-dots">
    {[0, 1, 2].map((i) => (
      <div
        key={i}
        className="h-1.5 w-1.5 rounded-full bg-gray-600 animate-dot-fade"
        style={{
          animationDelay: `${i * 0.2}s`,
        }}
      />
    ))}
  </div>
);

const DateOptionDropdown = () => {
  const filterState = useContext(FilterContext);
  const filterDispatch = useContext(FilterDispatchContext);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [selectedRange, setSelectedRange] = useState<DateRangeOption>(DateFilterUtility.getSelectedOption(filterState));

  useEffect(() => {
    setSelectedRange(DateFilterUtility.getSelectedOption(filterState));
  }, [filterState]);

  const handleSelectRange = (option: DateRangeOption) => {
    const payload = DateFilterUtility.getReplaceDateFiltersPayload(option.getDates());
    setSelectedRange(option);
    setIsOpen(false);
    filterDispatch({ type: FilterGroupActionType.AlterStaticFilters, payload });
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex items-center gap-2 rounded-full hover:text-raspberry duration-200">
        <CalendarIcon className="h-5 w-5 text-gray-400 -top-[1px] relative" />
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="font-medium text-blueberry hover:text-raspberry flex items-center gap-1 whitespace-nowrap "
          data-testid="date-filter-dropdown-button"
        >
          {selectedRange.label}
          <svg
            className={`ml-1 mr-2 h-4 w-4 transform fill-gray-400 duration-200 transition-all ease-out ${isOpen ? 'rotate-180' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute left-0 top-full mt-3 w-32 rounded-lg border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50"
          data-testid="date-filter-dropdown-options"
        >
          {DATE_RANGE_OPTIONS.map((option) => (
            <button
              key={option.label}
              className={`w-full px-4 py-3 text-left font-medium hover:bg-gray-100 border-b last:border-none last:rounded-b-lg first:rounded-t-lg border-gray-200 ${
                selectedRange.label === option.label ? 'bg-silver text-blueberry' : 'text-blueberry'
              }`}
              onClick={() => handleSelectRange(option)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DateFilterComponent;
