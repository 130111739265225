import { Board_Widget_Type, FilterInput, Widget } from '../../../generated/graphql';
import { LoadableCustomChartCard } from '../charts/CustomChartCard';

interface WidgetComponentFactoryProps {
  widget: Widget;
  boardId: number;
  removeWidget: (widget: Widget) => void;
  duplicateWidget: (widget: Widget) => void;
}

export const WidgetComponentFactory = (props: WidgetComponentFactoryProps) => {
  function handleRemoveWidget() {
    props.removeWidget(props.widget);
  }

  function handleDuplicateWidget() {
    props.duplicateWidget(props.widget);
  }

  if (props.widget.widgetType === Board_Widget_Type.ChartWidget) {
    return <ChartWidget widget={props.widget} boardId={props.boardId} removeWidget={handleRemoveWidget} duplicateWidget={handleDuplicateWidget} />;
  }
  return <div></div>;
};

interface WidgetComponentProps {
  widget: Widget;
  boardId: number;
  removeWidget: () => void;
  duplicateWidget: () => void;
}

const ChartWidget = (props: WidgetComponentProps) => {
  return (
    <div className="h-[19.4rem]">
      <LoadableCustomChartCard
        key={props.widget.id}
        chartId={props.widget.widgetTypeId}
        chartTitle={props.widget.title}
        deleteChart={props.removeWidget}
        duplicateWidget={props.duplicateWidget}
        boardId={props.boardId}
      />
    </div>
  );
};
