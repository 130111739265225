import { useContext, useRef, useState } from 'react';
import { FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterGroupActionType, FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { AppliedFilter } from '../appliedFilters/AppliedFilter';
import { DeleteButton } from './DeleteButton';
import { useClickOutside } from '../../../../v2/hooks/ClickOutside';
import { PlusIcon } from '@heroicons/react/24/outline';

export const RootFilter: React.FC<{ filter: FilterNodeState | undefined; setIsOpen: (isOpen: boolean) => void }> = ({ filter, setIsOpen }) => {
  const dispatch = useContext(FilterDispatchContext);
  if (!filter) return <></>;
  if (filter.type !== 'collection') throw new Error('Root filter must be a collection');
  const appliedFilter = filter;

  // We use this to determine whether to show the save and delete buttons
  // the applied filter is always a collection, but it may be empty (no items)
  // If the filter has items, it means the user has added at least one filter, so we show the save and delete buttons
  const isFilterPopulated = appliedFilter && appliedFilter.type === 'collection' && appliedFilter.items.length > 0;

  return (
    <div className="flex flex-col lg:flex-row gap-3 items-start justify-between">
      <div className="relative max-w-screen-lg flex flex-row flex-wrap flex-shrink gap-1 lg:gap-0">
        {filter.items.map((item, index) => (
          <>
            {index > 0 && (
              <div className="flex flex-col gap-2 lg:inline-flex lg:flex-row rounded-3xl relative group h-full pl-2 pr-2.5 py-2">
                <OperatorSelector filter={filter} />
              </div>
            )}
            <div
              className={`flex flex-col gap-2 lg:inline-flex lg:flex-row bg-gray-200 py-1.5 px-4 rounded-3xl relative group lg:h-8 mt-2px mb-2px w-full lg:w-auto`}
            >
              <AppliedFilter filter={item} key={item.id} />
            </div>
          </>
        ))}

        <div className="flex items-center gap-2 mt-3 lg:mt-0">
          {!isFilterPopulated && (
            <>
              <button
                className="flex items-center gap-1 text-blueberry font-semibold -mt-3 lg:mt-0 hover:text-white h-8 border-2 border-blueberry rounded-full pl-3 pr-4 pt-[1px] hover:bg-blueberry transition-all duration-150"
                onClick={() => setIsOpen(true)}
              >
                <PlusIcon className="w-4 h-4 -mt-1" />
                Add filter
              </button>
            </>
          )}

          {isFilterPopulated && (
            <>
              <button className="flex items-center gap-1 text-blueberry font-semibold ml-2 hover:text-raspberry lg:h-8" onClick={() => setIsOpen(true)}>
                <PlusIcon className="w-4 h-4" />
                Add filter
              </button>
              {/* <SaveButton onClick={() => {}} /> */}
              <DeleteButton
                onClick={() => {
                  dispatch({
                    type: FilterGroupActionType.RemoveFilterNode,
                    payload: {
                      id: appliedFilter.id,
                    },
                  });
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const OperatorSelector: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  const dispatch = useContext(FilterDispatchContext);
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside([ref], () => {
    setIsOpen(false);
  });

  const dropDown = (
    <div ref={ref} className="absolute left-0 bottom-0 top-full mt-1 w-32 h-full py-1 z-50" data-testid="operator-selector-dropdown-options">
      <div className="flex flex-col rounded-lg border-2 border-gray-200 bg-white shadow-lg">
        {[
          { label: 'and', value: 'and' },
          { label: 'or', value: 'or' },
        ].map((option) => (
          <button
            key={option.value}
            className={`w-full px-4 py-2 text-left h-full font-medium flex flex-row border-b border-gray-200 items-center justify-between hover:bg-gray-100 first:rounded-t-lg last:border-none last:rounded-b-lg ${
              filter.operator === option.value ? 'bg-silver text-blueberry' : 'text-blueberry'
            }`}
            onClick={(e) => {
              dispatch({
                type: FilterGroupActionType.UpdateCollectionOperator,
                payload: { id: filter.id, operator: option.value },
              });
              setIsOpen(false);
            }}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <button
        className="bg-white rounded-md font-semibold italic text-blueberry w-10 hover:text-raspberry transition-all duration-150 ease-out pr-0.5"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {filter.operator.toLowerCase()}
      </button>
      {isOpen && dropDown}
    </>
  );
};
