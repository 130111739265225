import { unique } from 'underscore';
import { FilterNodeState } from '../../reducers/filterStatement/filterStatementReducer';
import uuid from 'react-uuid';

export class FilterTree {
  private filterStatement: FilterNodeState;

  /**
   * We currently don't do any validation here to make sure the filter statement is valid.
   *
   * I don't love this. I feel like this is going to bite us with some weird bugs.
   */
  constructor(statement: FilterNodeState | undefined) {
    if (!statement) {
      this.filterStatement = {
        type: 'collection',
        operator: 'AND',
        items: [],
        id: uuid(),
      };
    } else {
      this.filterStatement = statement;
    }
  }

  /**
   * Returns all the fields that are used in a filter tree.
   * The intent is to use this to find which fields to display in the configuration dropdown.
   * We don't want to show the fields that are already used in the current filter.
   *
   * Example with one filter statement:
   * applied filters: [{"fieldName": "Entry.date", "operator": ">=", "value": "2024-01-01"}]
   * getAppliedFields() -> ["Entry.date"]
   *
   * Example with two filter statements:
   * filterStatement: {"type": "collection", "operator": "AND", "items": [{"type": "statement", "fieldName": "Entry.date", "operator": ">=", "value": "2024-01-01"}, {"type": "statement", "fieldName": "Entry.title", "operator": "contains", "value": "test"}]}
   * getAppliedFields() -> ["Entry.date", "Entry.title"]
   * @param filterNode
   * @returns
   */
  getAppliedFields(filterNode?: FilterNodeState): string[] {
    if (!this.filterStatement) {
      return [];
    }

    if (!filterNode) {
      filterNode = this.filterStatement;
    }

    if (filterNode.type === 'statement') {
      return [filterNode.fieldName];
    }

    return unique(filterNode.items.flatMap((item) => this.getAppliedFields(item)));
  }

  /**
   * Removes static conditions from the filter tree and returns only the "applied filters"
   * Applied filters are always under a collection node in the root of the filter tree.
   * All we have to do is remove the static conditions from the top level collection. There should only be one node remaining - the applied filters.
   *
   * Example:
   * filterStatement: {"type": "collection", "operator": "AND", "items": [{"type": "statement", "fieldName": "Entry.date", "operator": ">=", "value": "2024-01-01"}, {"type": "statement", "fieldName": "Entry.title", "operator": "contains", "value": "test"}]}
   * getAppliedFilters() -> {"type": "collection", "operator": "AND", "items": [{"type": "statement", "fieldName": "Entry.title", "operator": "contains", "value": "test"}]}
   * @returns
   */
  getAppliedFilters(): FilterNodeState {
    if (!this.filterStatement) {
      throw new Error('Filter statement is undefined');
    }

    if (this.filterStatement.type !== 'collection') {
      throw new Error('Filter statement is not a collection');
    }

    const items = this.filterStatement.items.filter((item) => !this.isStaticCondition(item));

    // I'm really confused why we would always expect to have at least one item in the applied filters??
    // assert(items.length >= 1, 'We should have at least one item per the test');

    // I'm not sure if this is the right approach here.
    if (items.length === 0) {
      return {
        type: 'collection',
        operator: 'AND',
        items: [],
        id: uuid(),
      };
    }

    return items[0];
  }

  /**
   * Returns all the static conditions from the filter tree.
   * Since static conditions are always at the top level of the filter tree, we can just return all the statements in the top level collection that act on "static fields" - date and title.
   * Example:
   * filterStatement: {"type": "collection", "operator": "AND", "items": [{"type": "statement", "fieldName": "Entry.date", "operator": ">=", "value": "2024-01-01"}, {"type": "statement", "fieldName": "Entry.title", "operator": "contains", "value": "test"}]}
   * getStaticConditions() -> [{"type": "statement", "fieldName": "Entry.date", "operator": ">=", "value": "2024-01-01"}, {"type": "statement", "fieldName": "Entry.title", "operator": "contains", "value": "test"}]
   * @returns
   */
  getStaticConditions(): FilterNodeState[] {
    if (!this.filterStatement) {
      throw new Error('Filter statement is undefined');
    }

    if (this.filterStatement.type !== 'collection') {
      throw new Error('Filter statement is not a collection');
    }

    const items = this.filterStatement.items.filter((item) => this.isStaticCondition(item));

    return items;
  }

  /**
   * This isn't really the static conditions. But this is a nice api to be able to infer the static conditions from the filter tree...
   *
   * We need to think about how to actually implement this.
   */
  private isStaticCondition(filterNode: FilterNodeState): boolean {
    return filterNode.type === 'statement' && (filterNode.fieldName === 'Entry.date' || filterNode.fieldName === 'Entry.createdAt');
  }

  removeNode(id: string, filterNode?: FilterNodeState): FilterNodeState | undefined {
    if (!this.filterStatement) throw new Error('Invalid filter statement!');
    filterNode = filterNode ?? this.filterStatement;

    switch (filterNode.type) {
      case 'collection':
        filterNode.items = filterNode.items.filter((item) => {
          if (item.id === id) {
            return false;
          }
          const updatedItem = this.removeNode(id, item);
          return updatedItem !== undefined;
        });
        break;
      case 'statement':
        return filterNode.id === id ? undefined : filterNode;
      default:
        throw new Error('Invalid filter node type!');
    }

    return filterNode;
  }
}
