import { FilterNodeState, FilterState } from '../../reducers/filterStatement/filterStatementReducer';

/**
 * This class and FilterTree serve a similar role and likely should be merged under a single class.
 */
export class FilterNode {
  constructor(private filterState: FilterState) {}

  getAppliedFilterFields(): string[] {
    const appliedFilters = this.filterState.appliedFilter;
    return this.getFieldsFromFilterNode(appliedFilters);
  }

  private getFieldsFromFilterNode(filterNode: FilterNodeState): string[] {
    if (filterNode.type === 'collection') {
      return filterNode.items.flatMap((item) => this.getFieldsFromFilterNode(item));
    }

    if (filterNode.type === 'statement') {
      return [filterNode.fieldName];
    }

    throw new Error('Invalid filter node type!');
  }
}
