import { useContext, useEffect, useRef, useState } from 'react';
import { FilterField, Operator, useGetFilterFieldsLazyQuery } from '../../../../generated/graphql';
import { FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { useClickOutside } from '../../../../v2/hooks/ClickOutside';
import { FieldDropdown } from './FieldDropDown';
import { FieldValueConfiguration } from './FieldValueConfiguration';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { FilterContext } from '../../../../context/filterStatementContext';
import LoadingSpinner from '../../../baseComponents/LoadingSpinner';

export const FilterConfigurationPopup = ({
  currentNode,
  appliedFilter,
  isOpen,
  setIsOpen,
}: {
  currentNode?: FilterNodeState;
  appliedFilter: FilterNodeState | undefined;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { curTeamId } = useValidTeamAppContext();
  const { filterConsumable } = useContext(FilterContext);

  const [selectedField, setSelectedField] = useState<FilterField | null>(null);

  const [getFilterFields, { data, loading }] = useGetFilterFieldsLazyQuery();

  const resetState = () => {
    setIsOpen(false);
    setSelectedField(null);
  };

  useEffect(() => {
    // what does this formRef do?
    if (isOpen && formRef.current) {
      // We pass the filterConsumable to the query and that will prevent the query for using the cached data :/
      // we want to pass in the filterConsumable so we know what filters match the current filterConsumable
      getFilterFields({
        variables: {
          teamId: curTeamId,
          fieldStatementFilters: filterConsumable,
        },
      });
    }
  }, [isOpen]);

  useClickOutside([formRef], () => {
    setIsOpen(false);
  });

  return (
    <form
      ref={formRef}
      tabIndex={-1}
      className={`${
        isOpen ? 'absolute top-full mt-2 border-2 border-gray-200 bg-white z-50 max-h-[400px] rounded-lg w-[80vw] lg:w-[33vw] shadow-lg' : 'hidden'
      }`}
    >
      <div className={`grid w-full grid-cols-${selectedField ? 2 : 1}`}>
        {loading ? (
          <div className="flex flex-col justify-center items-center h-full p-4 bg-gray-100">
            <div className="flex flex-row justify-center items-center mb-4">
              <LoadingSpinner />
            </div>
            <div className="flex flex-row justify-center items-center mt-3">
              <p className="text-sm text-gray-500 italic">Loading filter fields...</p>
            </div>
          </div>
        ) : (
          <FieldDropdown fields={data?.getFilterFields ?? []} setSelectedField={setSelectedField} selectedField={selectedField} />
        )}
        {selectedField && <FieldValueConfiguration selectedField={selectedField} resetFunction={resetState} currentNode={currentNode} />}
      </div>
    </form>
  );
};
