import { Operator } from '../../../generated/graphql';
import { AlterStaticFiltersPayload, FilterState, FilterStatementSchema } from '../../../reducers/filterStatement/filterStatementReducer';

export type DateRangeOption = {
  label: string;
  getDates: () => { start?: Date; end?: Date };
};

export type DateRangePayload = {
  start?: Date;
  end?: Date;
};

export const DATE_RANGE_OPTIONS: DateRangeOption[] = [
  {
    label: '7 days',
    getDates: () => ({
      start: new Date(new Date().setDate(new Date().getDate() - 7)),
    }),
  },
  {
    label: '30 days',
    getDates: () => ({
      start: new Date(new Date().setDate(new Date().getDate() - 30)),
    }),
  },
  {
    label: '90 days',
    getDates: () => ({
      start: new Date(new Date().setDate(new Date().getDate() - 90)),
    }),
  },
  {
    label: '1 yr',
    getDates: () => ({
      start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    }),
  },
  {
    label: 'All',
    getDates: () => ({}),
  },
];

export class DateFilterUtility {
  static readonly fieldName = 'Entry.date';

  static getStartDate(filterState: FilterState): Date | undefined {
    const dateFilters = filterState.staticConditions?.filter((node) => node.type === 'statement' && node.fieldName === this.fieldName);
    const startFilter = dateFilters?.find((filter) => filter.operator === '>=');
    return startFilter ? new Date(startFilter.value) : undefined;
  }

  static getEndDate(filterState: FilterState): Date | undefined {
    const dateFilters = filterState.staticConditions?.filter((node) => node.type === 'statement' && node.fieldName === this.fieldName);
    const endFilter = dateFilters?.find((filter) => filter.operator === '<=');
    return endFilter ? new Date(endFilter.value) : undefined;
  }

  static getSelectedOption(filterState: FilterState): DateRangeOption {
    const dateFilters = filterState.staticConditions?.filter((node) => node.type === 'statement' && node.fieldName === this.fieldName);

    if (!dateFilters || dateFilters.length === 0) {
      return DATE_RANGE_OPTIONS.find((option) => option.label === 'All')!;
    }

    const startFilter = dateFilters.find((filter) => filter.operator === '>=');
    const endFilter = dateFilters.find((filter) => filter.operator === '<=');

    if (!startFilter) {
      return DATE_RANGE_OPTIONS.find((option) => option.label === 'All')!;
    }

    const filterStart = new Date(startFilter.value);
    const filterEnd = endFilter ? new Date(endFilter.value) : undefined;

    // Check each predefined option
    for (const option of DATE_RANGE_OPTIONS) {
      const { start: optionStart, end: optionEnd } = option.getDates();

      // Skip comparison for 'All' option since it uses '-' as start
      if (option.label === 'All') {
        continue;
      }

      if (!optionEnd && filterEnd) {
        continue;
      }

      // Check if dates are within 24 hours of each other
      const startDiff = Math.abs(filterStart.getTime() - (optionStart as Date).getTime());

      const endDiff = filterEnd ? Math.abs(filterEnd.getTime() - (optionEnd as Date).getTime()) : undefined;

      if (startDiff <= 24 * 60 * 60 * 1000 && ((!endDiff && !optionEnd) || (endDiff && endDiff <= 24 * 60 * 60 * 1000))) {
        return option;
      }
    }

    // If no match found, return custom option
    return {
      label: 'Custom',
      getDates: () => ({
        start: filterStart,
        end: filterEnd,
      }),
    };
  }

  static getModifyDateFiltersPayload(dates: DateRangePayload): AlterStaticFiltersPayload {
    const filterNodes: FilterStatementSchema[] = [];

    if (dates.start) {
      filterNodes.push({
        type: 'statement',
        fieldName: this.fieldName,
        operator: '>=',
        value: dates.start.toISOString(),
      });
    }

    if (dates.end) {
      filterNodes.push({
        type: 'statement',
        fieldName: this.fieldName,
        operator: '<=',
        value: dates.end.toISOString(),
      });
    }

    return {
      upsert: {
        filterNodes,
      },
      remove: {
        fieldNames: [],
      },
    };
  }

  static getDateFilterStatement(dates: DateRangePayload): FilterStatementSchema[] {
    const filterNodes: FilterStatementSchema[] = [];
    if (dates.start) {
      filterNodes.push({
        type: 'statement',
        fieldName: this.fieldName,
        operator: '>=',
        value: dates.start.toISOString(),
      });
    }

    if (dates.end) {
      filterNodes.push({
        type: 'statement',
        fieldName: this.fieldName,
        operator: '<=',
        value: dates.end.toISOString(),
      });
    }

    return filterNodes;
  }

  /**
   * This clears any date filters and replaces them with the new dates
   * @param dates
   * @returns
   */
  static getReplaceDateFiltersPayload(dates: DateRangePayload): AlterStaticFiltersPayload {
    const filterNodes: FilterStatementSchema[] = this.getDateFilterStatement(dates);

    return {
      upsert: {
        filterNodes,
      },
      remove: {
        fieldNames: [this.fieldName],
      },
    };
  }
}
