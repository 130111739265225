import React, { useState } from 'react';
import { client } from '../../../client';
import { DigestDataFragment, DigestStatus, SendDigestDocument, SetDigestStatusDocument } from '../../../generated/graphql';
import Button, { ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import Badge from '../Badge';

interface DigestDataDisplayProps {
  digestId: number;
}

export const DigestStatusBadge = (props: { actions: DigestStatusActions }) => {
  const { actions } = props;
  return <Badge badge={{ text: actions.status, id: '0' }} smaller={true} color={actions.color} />;
};

export const DigestStatusActionButton = (props: { actions: DigestStatusActions }) => {
  const { actions } = props;
  const [loading, setLoading] = useState(false);

  const updateStatus = async () => {
    setLoading(true);
    await actions.updateStatus();
    setLoading(false);
  };

  return (
    <Button
      text={actions.buttonText}
      onClick={() => updateStatus()}
      loadingConfirm={loading}
      disabled={loading || actions.disable}
      variant={ButtonVariant.Secondary}
      expandHeight={false}
      size={ButtonSize.Small}
    />
  );
};

/**
 * Class for deciding what to show in the digest status and what action to take based on the digest status.
 *
 * Here digests can be draft, ready for review, approved, and sent at each status there's potentially a different action to take. Right now the only
 * difference is when the digest is in approved state we want to call sendDigest instead of setDigestStatus.
 */
export class DigestStatusActions {
  public buttonText: string = 'Ready For Review';
  public disable: boolean = false;
  public status: string = 'Draft';
  public color: string = 'bg-lemonhead';
  protected nextStatus: DigestStatus = DigestStatus.PendingReview;
  protected id: number;

  constructor(props: DigestDataDisplayProps) {
    this.id = props.digestId;
  }

  public updateStatus = async () => {
    await client.mutate({ mutation: SetDigestStatusDocument, variables: { digestId: this.id, status: this.nextStatus } });
  };
}

export class DigestStatusReadyForReview extends DigestStatusActions {
  public buttonText: string = 'Approve';
  public status: string = 'P. Review';
  public color: string = 'bg-mentos';
  protected nextStatus: DigestStatus = DigestStatus.Approved;
}
export class DigestStatusApproved extends DigestStatusActions {
  public buttonText: string = 'Send(jk)';
  public status: string = 'Approved';
  public color: string = 'bg-green-400';
  protected nextStatus: DigestStatus = DigestStatus.Sent;

  public updateStatus = async (): Promise<void> => {
    await client.mutate({ mutation: SendDigestDocument, variables: { digestId: this.id } });
  };
}

export class DigestStatusSent extends DigestStatusActions {
  public buttonText: string = 'Sent';
  public status: string = 'Sent';
  public color: string = 'bg-gray-400';
  public disable: boolean = true;
  protected nextStatus: DigestStatus = DigestStatus.Sent;

  public updateStatus = async (): Promise<void> => {
    throw new Error("You shouldn't be able to call this");
  };
}

export const getDigestStatus = (digest: DigestDataFragment): DigestStatusActions => {
  switch (digest.status) {
    case DigestStatus.Draft:
      return new DigestStatusActions({ digestId: digest.id });
    case DigestStatus.PendingReview:
      return new DigestStatusReadyForReview({ digestId: digest.id });
    case DigestStatus.Approved:
      return new DigestStatusApproved({ digestId: digest.id });
    case DigestStatus.Sent:
      return new DigestStatusSent({ digestId: digest.id });
  }
};
