import { useReducer } from 'react';
import { FilterContext, FilterDispatchContext } from '../../context/filterStatementContext';
import { filterStatementReducer } from '../../reducers/filterStatement/filterStatementReducer';
import { InitialFilterStateProvider } from '../filters/utilities/InitialFilterStateProvider';

export const FilterablePage = ({ teamId, children }: { teamId: number; children: React.ReactNode }) => {
  const [filterState, dispatch] = useReducer(filterStatementReducer, InitialFilterStateProvider.getInitialFilterState(teamId));

  return (
    <FilterContext.Provider value={filterState}>
      <FilterDispatchContext.Provider value={dispatch}>{children}</FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};
