import { FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { AppliedFilter } from '../appliedFilters/AppliedFilter';
import { OperatorSelector } from './RootFilter';

export const DisabledFilterDisplay: React.FC<{ filter: FilterNodeState | undefined }> = ({ filter }) => {
  if (!filter) return <></>;
  if (filter.type !== 'collection') throw new Error('Root filter must be a collection');

  return (
    <div className="flex flex-col lg:flex-row gap-3 items-start justify-between pointer-events-none">
      <div className="relative max-w-screen-lg flex flex-row flex-wrap flex-shrink gap-1 lg:gap-0">
        {filter.items.map((item, index) => (
          <>
            {index > 0 && (
              <div className="flex flex-col gap-2 lg:inline-flex lg:flex-row rounded-3xl relative group h-full pl-2 pr-2.5 py-2">
                <OperatorSelector filter={filter} />
              </div>
            )}
            <div
              className={`flex flex-col flex-wrap gap-2 lg:inline-flex lg:flex-row bg-gray-200 py-1.5 px-4 rounded-3xl relative group mt-2px mb-2px w-full lg:w-auto`}
            >
              <AppliedFilter filter={item} key={item.id} />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
