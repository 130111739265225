/**
 * An Applied Filter is a filter that the user has configured and saved.
 * This component is a visual representation of that filter.
 * We do this by converting the applied filter to human readable text and giving options to edit or delete the filter.
 */

import { FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { AppliedCollectionFilter } from './AppliedCollectionFilter';
import { AppliedStatementFilter } from './AppliedStatementFilter';

export const AppliedFilter: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  return filter.type === 'statement' ? <AppliedStatementFilter filter={filter} /> : <AppliedCollectionFilter filter={filter} />;
};