import { Operator } from "../../../generated/graphql";

/**
 * Adapts the operator string to a more human readable format for displaying on the frontend.
 * @param operator 
 * @returns 
 */
export const adaptForDisplay = (operator: string) => {
  switch (operator) {
    case '==':
      return 'is';
    case '!=':
      return 'is not';
    case '>=':
      return 'is greater than or equal to';
    case '<=':
      return 'is less than or equal to';
    case '~=':
      return 'matches';
    case 'in':
      return 'is in';
    case '!in':
      return 'is not in';
    default:
      return operator;
  }
};

/**
 * Adapts the operator to a format that can be used in the filter node.
 * This is what is sent to the backend.
 * example: IN -> in
 * @param operator 
 * @returns 
 */
export const adaptForFilterNode = (operator: Operator) => {
  switch (operator) {
    case Operator.In:
      return 'in';
    case Operator.NotIn:
      return '!in';
    case Operator.Equal:
      return '==';
    case Operator.NotEqual:
      return '!=';
    case Operator.GreaterThanOrEqual:
      return '>=';
    case Operator.LessThanOrEqual:
      return '<=';
    case Operator.Like:
      return '~=';
    default:
      return operator
  }
};

/**
 * Normalizes the operator to a more human readable format.
 * example: NOT_EQUAL -> Not Equal
 * @param operator 
 * @returns 
 */
export const normalizeOperator = (operator: Operator) => {
  return operator
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

/**
 * Checks if the operator supports multiple values.
 * example: Entry.source in ('a', 'b')
 * @param operator 
 * @returns 
 */
export const operatorSupportsMultipleValues = (operator: Operator) => {
    return [Operator.In, Operator.NotIn].includes(operator);
};