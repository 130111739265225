import { ApolloQueryResult } from '@apollo/client';
import { client } from '../../client';
import { GetPartialEntriesQuery, PartialEntryFragment, GetPartialEntriesDocument } from '../../generated/graphql';
import { PaginatedQuery, PaginationParams } from './PaginatedQuery';

export class GetEntriesInPages implements PaginatedQuery<ApolloQueryResult<GetPartialEntriesQuery>> {
  private teamId: number;

  private entries: any[];
  private filterStateConsumable: string;

  constructor(params: { teamId: number; filterStateConsumable: string }) {
    this.teamId = params.teamId;
    this.filterStateConsumable = params.filterStateConsumable;

    this.entries = [];
  }

  getEntries(): PartialEntryFragment[] {
    return this.entries;
  }

  onResolve(response: ApolloQueryResult<GetPartialEntriesQuery>): void {
    let entriesResponse = response.data.entries ?? [];
    this.entries.push(...entriesResponse);
  }

  fetch(params: PaginationParams): Promise<ApolloQueryResult<GetPartialEntriesQuery>> {
    const teamId = this.teamId;

    return client.query<GetPartialEntriesQuery>({
      fetchPolicy: 'no-cache',
      query: GetPartialEntriesDocument,
      variables: {
        teamId,
        filterStatement: this.filterStateConsumable,
        take: params.take,
        skip: params.skip,
      },
    });
  }
}
