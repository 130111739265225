import { useContext, useEffect, useRef, useState } from "react";
import { CheckmarkIcon } from "react-hot-toast";
import { FilterContext, FilterDispatchContext } from "../../../../context/filterStatementContext";
import { FilterFieldValue, Operator, useGetFilterFieldsLazyQuery, useGetFilterFieldValuesLazyQuery } from "../../../../generated/graphql";
import { FilterGroupActionType, FilterStatementState } from "../../../../reducers/filterStatement/filterStatementReducer";
import { useValidTeamAppContext } from "../../../../v2/contexts/AppContext";
import Button, { ButtonSize, ButtonVariant } from "../../../baseComponents/Button";
import DropDown from "../../../baseComponents/DropDown";
import LoadingSpinner from "../../../baseComponents/LoadingSpinner";
import { FilterTree } from "../../../lib/filterTree";
import { adaptForFilterNode, normalizeOperator, operatorSupportsMultipleValues } from "../operatorUtils";


export const AppliedFilterEditor = ({ 
    filterStatement, 
    setOpen,
}: { 
    filterStatement: FilterStatementState, 
    setOpen: (open: boolean) => void,
}) => {

    const [selectedOperator, setSelectedOperator] = useState<Operator | null>(filterStatement.operator as Operator);
    const [selectedValues, setSelectedValues] = useState<FilterFieldValue[]>([]);

    const [allowedOperators, setAllowedOperators] = useState<Operator[]>([]);

    const { curTeamId } = useValidTeamAppContext();
    const { filterConsumable } = useContext(FilterContext);

    const [getFilterFieldValues, { data: filterFieldValuesData, loading: filterFieldValuesLoading }] = useGetFilterFieldValuesLazyQuery();
    const [getFilterFields, _] = useGetFilterFieldsLazyQuery();


    useEffect(() => {
      if(filterStatement.value) {
        const value = JSON.parse(filterStatement.value)
        
        if(Array.isArray(value)) {
          
          setSelectedValues(value);
        } else {
          setSelectedValues([value]);
        }
      } else {
        setSelectedValues([]);
      }

      setSelectedOperator(null);
      const filterTree = new FilterTree(JSON.parse(filterConsumable));
      const updatedFilterTree = filterTree.removeNode(filterStatement.id);
      
      getFilterFieldValues({
        variables: {
          fieldName: filterStatement.fieldName,
          teamId: curTeamId,
          fieldStatementFilters: JSON.stringify(updatedFilterTree),
          skip: 0,
          take: 100,
        }
      });
      getFilterFields({
        variables: {
          teamId: curTeamId,
          fieldStatementFilters: filterConsumable,
        },
        onCompleted: (data) => {
          setAllowedOperators(data.getFilterFields.find((field) => field.fieldName === filterStatement.fieldName)?.allowedOperators.flat() || []);
        }
      });    
    }, [filterStatement, filterConsumable]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!isFirstRender.current && filterConsumable) {
            setOpen(false);
        }
        
        isFirstRender.current = false;
    }, [filterConsumable, setOpen]);

    const dispatch = useContext(FilterDispatchContext);
    
    return (
        <div className="flex w-full flex-col p-4 bg-white max-h-[420px] overflow-y-scroll absolute top-full left-0 border-2 border-gray-200 rounded-lg z-50 min-w-[400px] mt-2 shadow-lg">
          <div className="text-blueberry-light font-semibold mb-3">{filterStatement.fieldDisplayName}</div>
          {filterFieldValuesLoading ? (
            <div className="flex flex-col items-center justify-center py-10">
              <div className="flex-row">
                <LoadingSpinner />
              </div>
              <div className="text-blueberry-light flex-row text-sm py-2 animate-pulse italic">Loading filter configuration...</div>
            </div>
          ) : (
            <>
              <DropDown
                setSelectedItem={(item) => {
                  setSelectedOperator(item.value as Operator);
                }}
                selectedItem={{
                  name: normalizeOperator(selectedOperator || Operator.Equal),
                  id: selectedOperator || '',
                  displayName: normalizeOperator(selectedOperator || Operator.Equal),
                  value: selectedOperator || Operator.Equal,
                }}
                placeholderText="Select Operator"
                dropDownData={allowedOperators.map((operator) => ({
                  name: normalizeOperator(operator),
                  id: operator,
                  displayName: normalizeOperator(operator),
                  value: operator,
                }))}
              />
              <section className="flex flex-col max-h-[80%] overflow-y-scroll pb-4">
                {filterFieldValuesData?.getFilterFieldValues?.length ? (
                  <div className="flex flex-col mt-3">
                    {filterFieldValuesData?.getFilterFieldValues.map((value) => (
                      <div
                        className={`flex flex-row justify-between vertical-align-middle text-blueberry-light text-sm bg-gray-100 border border-gray-200 p-2 my-1 rounded-md cursor-pointer hover:bg-gray-200 ${
                          selectedValues.some((selectedValue) => selectedValue.displayName === value.displayName) ? 'bg-gray-200' : ''
                        }`}
                        onClick={() => {
                          if (selectedValues.some((selectedValue) => selectedValue.filterValue === value.filterValue)) {
                            setSelectedValues(selectedValues.filter((selectedValue) => selectedValue.filterValue !== value.filterValue));
                          } else {
                            if (operatorSupportsMultipleValues(selectedOperator || Operator.Equal)) {
                              setSelectedValues([...selectedValues, value]);
                            } else {
                              setSelectedValues([value]);
                            }
                          }
                        }}
                      >
                        <p className="max-w-[200px] overflow-ellipsis">{value.displayName}</p>
                        <div className="flex flex-col justify-center">
                          <div
                            className={`flex flex-center text-gray-500 font-semibold ${
                              selectedValues.some((selectedValue) => selectedValue.filterValue === value.filterValue) ? 'visible' : 'invisible'
                            }`}
                          >
                            <CheckmarkIcon className="w-4 h-4 vertical-align-middle" primary="gray-500" secondary="gray-500" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center py-10">
                    <p className="text-blueberry-light text-sm">No values found</p>
                  </div>
                )}
              </section>
              <div className="flex flex-row gap-x-2 justify-between pt-4 border-t-2 -mx-4 px-4">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Tertiary}
                  text="Cancel"
                  onClick={() => { setOpen(false); }}
                />
                <Button
                  size={ButtonSize.Small}
                  text="Apply"
                  disabled={selectedValues.length === 0}
                  onClick={() => {
                    dispatch({
                      type: FilterGroupActionType.UpdateFilterNode,
                      payload: {
                        id: filterStatement.id,
                        filterNode: {
                          ...filterStatement,
                          operator: adaptForFilterNode(selectedOperator || Operator.Equal),
                          value: selectedValues.length > 1 ? JSON.stringify(selectedValues.map((value) => value.filterValue)) : selectedValues[0].filterValue,
                          valueDisplayName: selectedValues.length > 1 ? selectedValues.map((value) => value.displayName).join(', ') : selectedValues[0].displayName,
                          fieldDisplayName: filterStatement.fieldDisplayName,
                        }
                      }
                    })
                    setOpen(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
    )
}