import { AlterStaticFiltersPayload, FilterState } from '../../../reducers/filterStatement/filterStatementReducer';

/**
 * this is a utility class that helps components interact with the entry text search filter
 */
export class EntryTextSearchFilter {
  static readonly fieldName = 'Entry.text';
  static readonly operator = '~=';
  static composeFilterUpdatePayload(queryString: string): AlterStaticFiltersPayload {
    return {
      upsert: { filterNodes: [{ type: 'statement', fieldName: this.fieldName, operator: this.operator, value: queryString }] },
      remove: { fieldNames: [] },
    };
  }
  static textSearchApplied(filterState: FilterState) {
    return filterState.staticConditions.some((condition) => condition.fieldName === this.fieldName);
  }
}
