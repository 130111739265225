import { Route, Routes, useParams } from 'react-router-dom';
import { BoardsPage } from './BoardsPage';
import { BoardPage } from './BoardPage';
import { BoardsListProvider } from '../../../context/boardsListContext';
import { ChartsPage } from '../ChartsPage';
import { FilterablePage } from '../FilterablePage';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { BoardProvider } from '../../../context/boardContext';
import { useGetBoardQuery } from '../../../generated/graphql';
import { PageWrapper } from '../PageWrapper';
import { BoardHeaderSectionSkeleton } from './sections/BoardHeaderSectionSkeleton';
import { BoardFilterSectionSkeleton } from './sections/BoardFilterSectionSkeleton';

export const BoardsRouterPage = () => {
  return (
    <Routes>
      <Route path={`/:boardId/*`} element={<SubRouter />} />
      <Route
        path={`/`}
        element={
          <BoardsListProvider>
            <BoardsPage />
          </BoardsListProvider>
        }
      />
    </Routes>
  );
};

const SubRouter = () => {
  const boardId = useParams().boardId;
  const { curTeamId } = useValidTeamAppContext();

  const { loading: loadingBoard, data: boardRes } = useGetBoardQuery({
    variables: {
      teamId: curTeamId,
      boardId: Number(boardId),
    },
    onCompleted: (data) => {
      /**
       * Todo: here you need to dispatch the board filters to the filter state.
       *
       * By doing this and blocking rendering charts we can always ensure that any applied board filters get applied into the
       * filter state before rendering any chart on either the boards page or on the chart page
       *
       * If the board doesn't exist i.e. boardId === -1 then we just continue, this means we're looking at a chart that doesn't belong to the boards page
       *
       * Note: how on the `BoardPage.tsx` we handle the case where boardId === -1 and redirect to the boards page
       */
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (loadingBoard)
    return (
      <PageWrapper title="">
        <SkeletonPage />
      </PageWrapper>
    );

  return (
    <FilterablePage teamId={curTeamId}>
      <BoardProvider
        initialState={{
          board: boardRes?.getBoard ?? {
            id: Number(boardId),
            title: '',
            description: '',
            isPinnedByUser: false,
            widgets: [],
            creator: {
              firstName: '',
            },
            filterInput: {},
          },
        }}
      >
        <Routes>
          <Route path={`/charts/:chartId`} element={<ChartsPage />} />
          <Route path={`/charts/creator`} element={<ChartsPage />} />
          <Route path={`/`} element={<BoardPage />} />
        </Routes>
      </BoardProvider>
    </FilterablePage>
  );
};

const SkeletonPage = () => {
  return (
    <div className="flex flex-col gap-y-3">
      <BoardHeaderSectionSkeleton backButton={undefined} />
      <BoardFilterSectionSkeleton />
    </div>
  );
};
