import { FilterContext, FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterGroupActionType, FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { useState, useContext } from 'react';
import { FilterConfigurationPopup } from '../filterConfiguration/FilterConfiguration';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';

export const AppliedFilterActions: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  const dispatch = useContext(FilterDispatchContext);
  const [isOpen, setIsOpen] = useState(false);
  const currentFilter = useContext(FilterContext);

  return (
    <>
      <div
        className="rounded-3xl top-0 pt-2.5 lg:pt-0 right-0 opacity-100 absolute lg:right-0 h-full lg:top-0 lg:bottom-0 flex flex-row items-start lg:items-center lg:opacity-0 transition-all duration-200 lg:group-hover:opacity-100"
        style={{
          background: 'linear-gradient(to right, rgba(229, 231, 235, 0) 0%, rgba(229, 231, 235, 1) 50%)',
        }}
      >
        <div className="w-8"></div>
        <div className="lg:bg-gray-200 rounded-r-3xl flex flex-row items-center gap-2 pl-0 pr-3">
          <button
            name="delete-button"
            className="hover:text-raspberry"
            onClick={() => {
              dispatch({
                type: FilterGroupActionType.RemoveFilterNode,
                payload: {
                  id: filter.id,
                },
              });
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </button>
          <button name="plus-button" className="hover:text-raspberry" onClick={() => setIsOpen(!isOpen)}>
            <PlusIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
      <FilterConfigurationPopup currentNode={filter} appliedFilter={currentFilter.appliedFilter} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
