import { useChartState } from '../../../context/chartContext';
import { useChartDispatch } from '../../../context/chartContext';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import { Action, Resource } from '../../../generated/graphql';
import { AppRoutes } from '../../../Routes';
import InlineEditableTextArea from '../../../baseComponents/InlineEditableTextArea';
import { useBoardState } from '../../../context/boardContext';

const TitleBar = () => {
  let navigate = useNavigate();
  const { board } = useBoardState();

  // why do we check both can save and can create charts??
  const { hasPermission } = useContext(PermissionsContext);
  const canCreateCharts = hasPermission(Resource.Charts, Action.Create) && hasPermission(Resource.Charts, Action.Update);
  const canEditCharts = hasPermission(Resource.Charts, Action.Update);
  const chartState = useChartState();
  const chartDispatch = useChartDispatch();

  const [loading, setLoading] = useState(false);

  /**
   * navigate back to the board page
   */
  const navigateToBoard = () => {
    let navigatePath;
    if (board.id !== -1) navigatePath = `${AppRoutes.v3FullPath.boards}/${board.id}`;
    else navigatePath = `${AppRoutes.v3FullPath.boards}`;
    navigate(navigatePath);
  };

  const handleSaveChart = async () => {
    setLoading(true);
    // await handleSaveChart();
    setLoading(false);
  };

  return (
    <div className="flex flex-col md:flex-row gap-y-4 justify-between mb-4 gap-x-8 ">
      <div className="flex flex-row items-center gap-x-2 w-full">
        <Button
          id="back-button"
          variant={ButtonVariant.Tertiary}
          shape={ButtonShape.Pill}
          size={ButtonSize.Small}
          text="Back"
          onClick={() => navigateToBoard()}
          icon={<p>{'<'}</p>}
          iconPosition="left"
        />
        <div className="w-full">
          <InlineEditableTextArea
            placeholder={'Enter a title...'}
            ignorePadding
            editable={canEditCharts}
            textAreaId="chart-title"
            value={chartState.chartConfigs?.title ?? ''}
            onEditComplete={(newText: string) => {
              chartDispatch({ type: ChartActionType.UpdateChartTitle, payload: { title: newText } });
            }}
            additionalClassNames={
              'items-center text-blueberry h-auto focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
            }
            textClassName="text-3xl xl:text-4xl font-semibold"
          />
        </div>
      </div>
      <div className="flex flex-row gap-x-2 items-center">
        <Button
          id="cancel-chart-creation"
          shape={ButtonShape.Pill}
          size={ButtonSize.Small}
          variant={ButtonVariant.Tertiary}
          text="Cancel"
          onClick={navigateToBoard}
        />
        <Button
          id="save-chart"
          shape={ButtonShape.Pill}
          size={ButtonSize.Small}
          variant={ButtonVariant.Primary}
          loadingConfirm={loading}
          disabled={!canCreateCharts}
          disabledTooltip="You don't have permission to create charts"
          text="Save Chart"
          onClick={async () => {
            await handleSaveChart();
          }}
        />
      </div>
    </div>
  );
};

export default TitleBar;
