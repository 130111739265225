import { PlusIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Chart_Type, useGetPlotLazyQuery, useGetPlotPreviewLazyQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { SeriesConfiguration } from './SeriesConfiguration';
import { BaseChartCard, BaseChartCardBody } from './CustomChartCard';
import { FilterContext } from '../../../context/filterStatementContext';
import { useChartState, useChartDispatch } from '../../../context/chartContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import EditChartSettings from './EditChartSettings';
import TitleBar from './TitleBar';
import ChartHeader from './ChartHeader';
import { Plot } from './Plot';
import InheritedBoardFiltersSection from './InheritedBoardFiltersSection';

export const EditChartPage = () => {
  const { curTeamId: teamId, curOrgId: orgId, organizations } = useValidTeamAppContext();
  const teams = organizations.find((org) => org.id === orgId)?.teams;
  const teamsList = teams?.map((team) => ({ id: team.id, name: team.name })) || [];

  /**
   * What is the initialLoadComplete for, what are we preventing from showing a loading spinner or something?
   */
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  /**
   * This is the filter state from the boards page.
   * This controls the date filters on the charts page.
   * If you set the date filter on the charts page and go to the boards page it'll be what you set the date to.
   *
   * If you set any filters on the boards page they automatically get consumed on the charts page through this context
   */
  const pageLevelFilterState = useContext(FilterContext);

  const chartState = useChartState();
  const chartDispatch = useChartDispatch();

  const { chartId } = useParams<{ chartId: string }>();

  const [getPlot] = useGetPlotLazyQuery();

  const [getPreview] = useGetPlotPreviewLazyQuery();

  const fetchPlotPreview = async () => {
    chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: true } });
    await getPreview({
      variables: { teamId, pageLevelFilterNode: pageLevelFilterState.filterConsumable, plotConfigurationInput: chartState.chartConfigs },
      onCompleted(data) {
        chartDispatch({ type: ChartActionType.SetCurrentChart, payload: { currentChart: data.getPlotPreview } });
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
      onError(error) {
        toast.error('Error fetching chart data');
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
    });
  };

  // these two useEffects compete with each other. We need a way to know which one to use or to be
  // smarter about how plotChanged is being set
  useEffect(() => {
    if (!initialLoadComplete) return;
    fetchPlotPreview();
  }, [chartState.plotChanged, pageLevelFilterState.filterConsumable]);

  useEffect(() => {
    console.log('first use effect');
    if (chartId)
      // If we have a chartId we want to fetch the plot otherwise we're creating a new plot
      fetchPlotById().then(() => {
        setInitialLoadComplete(true);
      });
    else {
      fetchPlotPreview().then(() => {
        setInitialLoadComplete(true);
      });
    }
  }, []);

  const fetchPlotById = async () => {
    console.log('fetchPlotById');
    chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: true } });
    await getPlot({
      variables: { teamId, chartId: Number(chartId), pageLevelFilterNode: pageLevelFilterState.filterConsumable },
      onCompleted(data) {
        chartDispatch({ type: ChartActionType.SetChartConfigs, payload: { chartConfigs: data.getPlot.plotConfiguration } });
        chartDispatch({ type: ChartActionType.SetCurrentChart, payload: { currentChart: data.getPlot } });
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
      onError(error) {
        toast.error('Error fetching chart data');
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
    });
  };

  /**
   * This handles saving the chart but why do we need the mode?
   */
  // const handleSaveChart = async () => {
  //   mode === 'creator' ? await createCustomChart(() => navigateToBoard()) : await editCustomChart(Number(chartId), () => navigateToBoard());
  // };

  return (
    <div className="flex flex-col">
      <TitleBar />
      <EditChartSettings />

      <div className="grid grid-cols-12 gap-y-8 xl:gap-y-0 xl:divide-x-2 xl:divide-gray-100 rounded-3xl w-full">
        {!initialLoadComplete && chartState.loadingChart ? (
          <div className="col-span-12">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="col-span-12 xl:col-span-4 xl:pr-4 flex flex-col gap-y-5 relative">
              <AddDataSeriesButton />
              <InheritedBoardFiltersSection />
              {chartState.chartConfigs?.series.map((series, index) => {
                return <SeriesConfiguration key={index} teamsList={teamsList} defaultTeamId={teamId} series={series} index={index} />;
              })}
            </div>
            <div className="col-span-12 xl:col-span-8 xl:pl-4 ">
              <div className="flex flex-col gap-y-3">
                <ChartHeader />
                <div className="flex flex-col h-96" id="section">
                  {chartState.currentChart ? (
                    <BaseChartCard>
                      <BaseChartCardBody>
                        <Plot plotData={chartState.currentChart} showLegend={chartState.chartConfigs?.chartType !== Chart_Type.HorizontalBar} />
                      </BaseChartCardBody>
                    </BaseChartCard>
                  ) : (
                    <LoadingSpinner />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const AddDataSeriesButton = () => {
  const chartDispatch = useChartDispatch();

  return (
    <div className="flex flex-row justify-between items-center text-blueberry">
      <h1 className="font-semibold text-lg">Data</h1>
      <Tippy theme="dark" content={<p>You can add a new data series to the chart to compare data between views</p>}>
        <div className="add-chart-data-series cursor-pointer" onClick={() => chartDispatch({ type: ChartActionType.AddSeries, payload: {} })}>
          <PlusIcon className="h-4 w-4 stroke-2" />
        </div>
      </Tippy>
    </div>
  );
};
