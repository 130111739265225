import { FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { OperatorSelector } from '../filterBar/RootFilter';
import { AppliedFilterActions } from './AppliedFilterActions';
import { AppliedStatementFilter } from './AppliedStatementFilter';

export const AppliedCollectionFilter: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  if (filter.type !== 'collection') throw new Error('AppliedCollectionFilter must be a collection');

  return (
    <div className={`flex flex-col gap-2 lg:inline-flex lg:flex-row flex-wrap relative group last:pr-4 last:-mr-4 ${filter.items.length > 1 ? '' : ''}`}>
      {filter.items.map((item, index) => (
        <>
          {index > 0 && (
            <div className="flex flex-col gap-2 lg:inline-flex lg:flex-row rounded-3xl relative">
              <OperatorSelector filter={filter} />
            </div>
          )}

          {item.type === 'statement' && <AppliedStatementFilter filter={item} />}
        </>
      ))}
      <AppliedFilterActions filter={filter} />
    </div>
  );
};
