import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Action, Resource } from '../../../generated/graphql';
import { AppRoutes } from '../../../Routes';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import { useIsMount } from '../../../v2/util';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import { WidgetsSection } from '../../components/boards/board/WidgetsSection';
import { PageWrapper } from '../PageWrapper';
import { BoardFilterSection } from './sections/BoardFilterSection';
import { BoardHeaderSection } from './sections/BoardHeaderSection';
import { AddBoardWidgetModal } from '../../components/Modals/AddBoardWidgetModal';
import { useBoardState } from '../../../context/boardContext';
import toast from 'react-hot-toast';
import FilterBarWrapper from '../../components/filters/filterBar/FilterBar';
import DateFilterComponent from '../../components/filters/filterBar/DateFilterComponent';

export const BoardPage = () => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const navigate = useNavigate();
  const boardState = useBoardState();

  /**
   * WHYYY DO WE ALWAYS NEED TO CHECK IS FIRST RENDER????
   *
   * LIKE WTF does this do?
   */
  const isFirstRender = useIsMount();
  useEffect(() => {
    if (!isFirstRender) navigate(AppRoutes.v3FullPath.boards, { replace: true });
  }, [teamId]);

  if (boardState.board.id === -1) {
    /**
     * This _shouldn't_ happen. If it does, we'll just redirect to the boards page
     */
    toast.error('Board not found');
    navigate(AppRoutes.v3FullPath.boards);
    return <></>;
  }

  return (
    <PageWrapper title="">
      <BoardPageBody />
    </PageWrapper>
  );
};

const BoardPageBody = () => {
  // Todo - need to load in default filters for the board into the filter state

  return (
    <>
      <div className="flex flex-col gap-y-3">
        <BoardTopSection />
        <WidgetsSection />
      </div>
    </>
  );
};

const BoardTopSection = () => {
  const { curTeamId: teamId } = useValidTeamAppContext();

  return (
    <div className="flex flex-col gap-y-3">
      <BoardHeaderSection />
      <div className="flex flex-col-reverse lg:flex-row items-start gap-x-5 pb-3 w-full justify-between">
        <FilterBarWrapper />
        <div className="flex-1 h-0.5 bg-gray-300 rounded-md mt-[16px]" /> {/* Added vertical divider */}
        <DateFilterComponent teamId={teamId} />
      </div>
      {/* <BoardFilterSection
        leftSideUI={
        }
      /> */}
    </div>
  );
};
